import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet-async';
import Faq from 'react-faq-component';
import Footer from '../../components/Footer/Footer';
import LandingNav from '../../components/LandingNav/LandingNav';
import { set, useForm } from "react-hook-form";
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import TextareaInput from '../../components/TextareaInput/TextareaInput';
import TryCta from '../../components/TryCta/TryCta';
import axios from 'axios';

import './style.scss';

const LandingContact = () => {
    const { user, editUser } = useSelector((state) => state.user);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({ mode: "onChange" })
    const [istSubmitting, setistSubmitting] = useState(false);
    const [isSent, setisSent] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    const onSubmit = (data) => {
        setistSubmitting(true);

        axios.post(`${process.env.REACT_APP_API_URL}/apify/send-contact-email`, {
            firstName: data.name,
            lastName: data.lastName,
            email: data.email,
            text: data.text
        })
        .then(res => { 
            console.log(res); 
            setisSent(true);
        })
        .catch(err => {
            console.log(err);
        })
    }

    var seo = {
        title: "Venco | La meilleure solution pour collecter davantage d'avis Google",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className='landing_fonctionnalites dark'>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LandingNav />

            <div className="landing_fonctionnalites-container">

                <form className='landing_fonctionnalites-container-form-container' onSubmit={handleSubmit(onSubmit)}>
                    <h2 className="landing_fonctionnalites-container-form-container-title">Contactez-nous</h2>
                    <h1 className="landing_fonctionnalites-container-form-container-subtitle">Nous répondons en général en moins de 24h.</h1>

                    {!isSent && <>
                        <TextInput
                            label="Nom"
                            name="lastName"
                            placeholder="Votre nom"
                            type="text"
                            register={
                            register("lastName", {
                                required: "Nom requis"
                            })
                            }
                            style="personnal-input"
                            error={errors.lastName ? errors.lastName.message : ''}
                            value={user?.lastName ? user?.lastName : ""}
                        />
                        <TextInput
                            label="Prénom"
                            name="name"
                            placeholder="Votre Prénom"
                            type="text"
                            register={
                            register("name", {
                                required: "Prénom requis"
                            })
                            }
                            style="personnal-input"
                            error={errors.name ? errors.name.message : ''}
                            value={user?.firstName ? user?.firstName : ""}
                        />
                        <TextInput
                            label="Email"
                            name="email"
                            placeholder="example@gmail.com"
                            type="text"
                            register={
                            register("email", {
                                required: "Email requis"
                            })
                            }
                            style="personnal-input"
                            error={errors.email ? errors.email.message : ''}
                            value={user?.email ? user?.email : ""}
                        />
                        <TextareaInput
                            label="Message"
                            name="text"
                            placeholder="Lorem ispum dolor sit amet..."
                            type="text"
                            register={
                            register("text", {
                                required: "text requis"
                            })
                            }
                            style="personnal-input"
                            error={errors.text ? errors.text.message : ''}
                            value={""}
                        />
                        <CustomButton isDisabled={istSubmitting ? true : false} type="submit" style="submit-cta" title={"Envoyer"} />
                    </>}

                    {isSent && <p className="landing_fonctionnalites-container-form-container-subtitle">Message envoyé !</p>}

                    <TryCta />

                </form>

                <Footer />


            </div>
        </div>
    )
}

export default LandingContact;