import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch} from "react-redux";
import { Link, NavLink, useParams, useLocation, Navigate } from "react-router-dom";
import { getUserInfo, fetchPlacesReviews, fetchPrizesReviews, fetchRewards } from '../../utils';
import { Helmet } from 'react-helmet-async';
import { UserLogin } from '../../redux/userSlice';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr';  // Import French locale
import { useDownloadExcel } from 'react-export-table-to-excel';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import TopData from '../../components/TopData/TopData';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { FiCalendar, FiTrendingUp, FiUsers, FiStar, FiBarChart2 } from 'react-icons/fi';

// Set moment to use French locale
moment.locale('fr');

import LeftNav from "../../components/LeftNav/LeftNav";


import './style.scss';

const COLORS = ['#ffbc43', '#2196f3', '#4caf50', '#f44336', '#9c27b0', '#ff9800', '#00bcd4', '#795548'];

const DataAnalytics = () => {
    const { menuOppened } = useSelector((state) => state.global);
    const { places } = useSelector((state) => state.place);
    const { prizes } = useSelector((state) => state.prize);
    const { reviews } = useSelector((state) => state.review);
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const tableRef = useRef(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [selectedPrize, setSelectedPrize] = useState('all');
    const [analyticsData, setAnalyticsData] = useState({
        totalClicks: 0,
        totalReviews: 0,
        conversionRate: 0,
        averageRating: 0,
        reviewsByPrize: [],
        clicksByDay: [],
        reviewDistribution: [],
        topPerformingPrizes: [],
        reviewGrowth: 0,
        engagementRate: 0,
        peakHours: [],
        rewardsDistribution: [],
    });
    const [selecting, setSelecting] = useState(false);

    const fetchAllReviews = async (test) => {
        await fetchPrizesReviews(user?.token, dispatch, "reviews/get-prize-reviews", {"prizeId": test});
    };
    useEffect(() => {
        if (typeof user?.token !== undefined && Object.keys(prizes)?.length !== 0 && Object.keys(reviews).length === 0) {
            const fetchAllPrizeReviews = async () => {
                await Promise.all(prizes.map(prize => fetchAllReviews(prize._id)));
            };
            fetchAllPrizeReviews();
        }
    }, [prizes]);
    
    if (user?.stripeProductId !== process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_ID_PROD && user?.role !== "admin") {
        return (<Navigate to={'/dashboard'} replace />);
    };

    var seo = {
        title: "Venco | Analyse des données client",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    useEffect(() => {
        calculateAnalytics();
    }, [dateRange[0].startDate, dateRange[0].endDate, selectedPrize, reviews, prizes]);

    const calculateAnalytics = () => {
        const filteredReviews = reviews.filter(review => {
            const reviewDate = new Date(review.createdAt);
            const matchesDate = reviewDate >= dateRange[0].startDate && reviewDate <= dateRange[0].endDate;
            const matchesPrize = selectedPrize === 'all' || review.placeReviewed === selectedPrize;
            return matchesDate && matchesPrize;
        });

        // Calculate total clicks within date range
        const totalClicks = prizes.length > 0 ? prizes.reduce((acc, prize) => {
            if (selectedPrize === 'all' || prize._id === selectedPrize) {
                const clicksInRange = (prize.clickHistory || []).filter(click => {
                    const clickDate = new Date(click.timestamp);
                    return clickDate >= dateRange[0].startDate && clickDate <= dateRange[0].endDate;
                }).length;
                return acc + clicksInRange;
            }
            return acc;
        }, 0) : 0;

        // Calculate basic metrics
        const totalReviews = filteredReviews.length;
        const conversionRate = totalClicks ? ((totalReviews / totalClicks) * 100).toFixed(2) : 0;
        
        // Calculate average rating
        const averageRating = filteredReviews.reduce((acc, review) => 
            acc + (review.clientInfos?.reviewRating || 0), 0) / totalReviews || 0;

        // Calculate review growth (compared to previous period)
        const previousPeriodStart = new Date(dateRange[0].startDate);
        previousPeriodStart.setDate(previousPeriodStart.getDate() - 30);
        const previousPeriodReviews = reviews.filter(review => {
            const reviewDate = new Date(review.createdAt);
            return reviewDate >= previousPeriodStart && reviewDate < dateRange[0].startDate;
        }).length;
        const reviewGrowth = previousPeriodReviews ? 
            (((totalReviews - previousPeriodReviews) / previousPeriodReviews) * 100).toFixed(2) : 0;

        // Calculate engagement rate (reviews with text/images)
        const engagedReviews = filteredReviews.filter(review => 
            review.clientInfos?.reviewText?.length > 0 || 
            review.clientInfos?.reviewImages?.length > 0
        ).length;
        const engagementRate = ((engagedReviews / totalReviews) * 100).toFixed(2);

        // Calculate peak hours
        const hourlyDistribution = {};
        filteredReviews.forEach(review => {
            const hour = new Date(review.createdAt).getHours();
            hourlyDistribution[hour] = (hourlyDistribution[hour] || 0) + 1;
        });
        const peakHours = Object.entries(hourlyDistribution)
            .sort(([,a], [,b]) => b - a)
            .slice(0, 3)
            .map(([hour, count]) => ({
                hour: `${hour}:00`,
                count
            }));

        // Calculate reviews by prize
        const reviewsByPrize = prizes.length > 0 ? prizes
            .filter(prize => selectedPrize === 'all' || prize._id === selectedPrize)
            .map(prize => {
                const prizeClicks = (prize.clickHistory || []).filter(click => {
                    const clickDate = new Date(click.timestamp);
                    return clickDate >= dateRange[0].startDate && clickDate <= dateRange[0].endDate;
                }).length;

                const prizeReviews = filteredReviews.filter(review => 
                    review.placeReviewed === prize._id
                ).length;

                return {
                    name: prize.name,
                    reviews: prizeReviews,
                    clicks: prizeClicks
                };
            }) : [];

        // Group reviews by day
        const clicksByDay = {};
        filteredReviews.forEach(review => {
            const day = moment(review.createdAt).format('YYYY-MM-DD');
            clicksByDay[day] = (clicksByDay[day] || 0) + 1;
        });

        // Calculate rewards distribution
        const rewardsMap = new Map();
        filteredReviews.forEach(review => {
            console.log(review);
            
            if (review.rewardUsed) {
                const rewardName = review.rewardUsed;
                rewardsMap.set(rewardName, (rewardsMap.get(rewardName) || 0) + 1);
            }
        });

        const rewardsDistribution = Array.from(rewardsMap, ([name, value]) => ({
            name,
            value
        })).sort((a, b) => b.value - a.value);

        setAnalyticsData({
            totalClicks,
            totalReviews,
            conversionRate,
            averageRating,
            reviewsByPrize,
            clicksByDay: Object.entries(clicksByDay).map(([date, count]) => ({
                date,
                reviews: count
            })),
            reviewDistribution: reviewsByPrize,
            reviewGrowth,
            engagementRate,
            peakHours,
            rewardsDistribution,
        });

        console.log(analyticsData);
        
    };

    const handleDateSelect = (ranges) => {
        if (!selecting) {
            // First click - set start date
            console.log("First click - set start date");
            const startDate = new Date(ranges.selection.startDate);
            startDate.setHours(0, 0, 0, 0); // Set to beginning of day

            setDateRange([{
                startDate: startDate,
                endDate: startDate,
                key: 'selection'
            }]);
            setSelecting(true);
        } else {
            // Second click - set end date
            console.log("Second click - set end date");
            const startDate = dateRange[0].startDate;
            const endDate = new Date(ranges.selection.endDate);
            endDate.setHours(23, 59, 59, 999); // Set to end of day
            
            // If end date is before start date, swap them
            if (endDate < startDate) {
                setDateRange([{
                    startDate: endDate,
                    endDate: startDate,
                    key: 'selection'
                }]);
            } else {
                setDateRange([{
                    startDate: startDate,
                    endDate: endDate,
                    key: 'selection'
                }]);
            }
            setSelecting(false);
            setShowDatePicker(false);
        }
    };

    // Also add this useEffect to handle clicking outside the date picker
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showDatePicker && !event.target.closest('.date-range-picker') && !event.target.closest('.date-selector')) {
                setShowDatePicker(false);
                setSelecting(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [showDatePicker]);

    return (
        <div className={`dashboard ${menuOppened ? "oppened" : "closed"}`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">
                <div className="content-container data-analytics">
                    <div className="header-section">
                        <div className="title-section">
                            <h1 className='data-analytics-title'>Analytics</h1>
                            <p className="data-analytics-subtitle">Retrouvez ici toutes les analyses données relatives à vos avis clients</p>
                        </div>
                        
                        <div className="filters-section">
                            <div className="date-selector" onClick={() => setShowDatePicker(!showDatePicker)}>
                                <FiCalendar />
                                <span>
                                    {moment(dateRange[0].startDate).format('DD MMM YYYY')} - {moment(dateRange[0].endDate).format('DD MMM YYYY')}
                                </span>
                            </div>
                            {showDatePicker && (
                                <div className="date-range-picker">
                                    <DateRange
                                        ranges={dateRange}
                                        onChange={handleDateSelect}
                                        months={2}
                                        direction="horizontal"
                                        moveRangeOnFirstSelection={true}
                                        minDate={addDays(new Date(), -365)}
                                        maxDate={new Date()}
                                        dragSelectionEnabled={false}
                                    />
                                </div>
                            )}
                            <select 
                                value={selectedPrize} 
                                onChange={(e) => setSelectedPrize(e.target.value)}
                                className="prize-select"
                            >
                                <option value="all">Toutes les roues</option>
                                {prizes.length > 0 && prizes.map(prize => (
                                    <option key={prize._id} value={prize._id}>
                                        {prize.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="metrics-grid">
                        <div className="metric-card">
                            <div className="metric-icon">
                                <FiUsers />
                            </div>
                            <div className="metric-content">
                                <h3>Clics totaux</h3>
                                <div className="metric-value">{analyticsData.totalClicks}</div>
                                <div className={`metric-trend ${analyticsData.reviewGrowth > 0 ? 'positive' : 'negative'}`}>
                                    <FiTrendingUp />{analyticsData.reviewGrowth}%
                                </div>
                            </div>
                        </div>

                        <div className="metric-card">
                            <div className="metric-icon">
                                <FiStar />
                            </div>
                            <div className="metric-content">
                                <h3>Avis collectés</h3>
                                <div className="metric-value">{analyticsData.totalReviews}</div>
                                <div className="metric-trend">
                                    Note moyenne: {analyticsData.averageRating.toFixed(1)}/5
                                </div>
                            </div>
                        </div>

                        <div className="metric-card">
                            <div className="metric-icon">
                                <FiBarChart2 />
                            </div>
                            <div className="metric-content">
                                <h3>Taux de conversion</h3>
                                <div className="metric-value">{analyticsData.conversionRate}%</div>
                                <div className="metric-trend">
                                    Engagement: {analyticsData.engagementRate}%
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="analytics-grid">
                        <div className="analytics-card chart">
                            <h3>Distribution des avis par roue</h3>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={analyticsData.reviewsByPrize}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="reviews" fill="#ffbc43" name="Avis" />
                                    <Bar dataKey="clicks" fill="#82ca9d" name="Clics" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        <div className="analytics-card chart">
                            <h3>Évolution des avis dans le temps</h3>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={analyticsData.clicksByDay}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="reviews" fill="#ffbc43" name="Avis" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        <div className="analytics-card chart">
                            <h3>Distribution des récompenses gagnées</h3>
                            <ResponsiveContainer width="100%" height={300}>
                                <PieChart>
                                    <Pie
                                        data={analyticsData.rewardsDistribution}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={true}
                                        label={({name, percent}) => `${name} (${(percent * 100).toFixed(0)}%)`}
                                        outerRadius={100}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {analyticsData.rewardsDistribution.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip 
                                        formatter={(value, name) => [`${value} gagnés`, name]}
                                        labelFormatter={() => ''}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            <div className="rewards-total">
                                <p>Total des récompenses gagnées: {
                                    analyticsData.rewardsDistribution.reduce((acc, curr) => acc + curr.value, 0)
                                }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DataAnalytics