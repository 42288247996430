import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useParams, useLocation, Navigate } from "react-router-dom";
import { getUserInfo, fetchPlaces, fetchRewards, handleFileUpload, apiRequest } from '../../utils';
import LeftNav from "../../components/LeftNav/LeftNav";
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import { Helmet } from 'react-helmet-async';
import { Wheel } from 'react-custom-roulette';
import { set, useForm } from "react-hook-form";

import './style.scss';

const PrizeForm = () => {
    const { menuOppened } = useSelector((state) => state.global);
    const { user } = useSelector(state => state.user);
    const { id } = useParams();
    const location = useLocation();
    const prizeToEdit = location.state?.prizeData;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({ mode: "onChange" });
    const [errMsg, setErrMsg] = useState("");
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [currentPrize, setCurrentPrize] = useState([]);
    const [prizeData, setprizeData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingPercentage, setEditingPercentage] = useState('');
    const [googleMapsUrl, setGoogleMapsUrl] = useState('');
    const [formError, setFormError] = useState('');
    const [wheelName, setWheelName] = useState('');
    const [wheelStatus, setWheelStatus] = useState(prizeToEdit?.status || 'draft');
    const [placeId, setPlaceId] = useState('');

    let {pathname} = useLocation();

    useEffect(() => {
        if (prizeToEdit) {
            // Pre-fill the form with prize data
            setWheelName(prizeToEdit.name);
            setGoogleMapsUrl(prizeToEdit.googleMapsUrl);
            setCurrentPrize(prizeToEdit.reward.map(r => ({
                name: r.name,
                percentage: r.percentage
            })));
            setprizeData(prizeToEdit.reward.map(r => ({
                option: r.name,
                optionSize: r.percentage
            })));
        }
    }, [prizeToEdit]);

    const calculateTotalPercentage = (rewards, excludeIndex = null) => {
        return rewards.reduce((total, reward, index) => {
            if (index === excludeIndex) return total;
            return total + reward.percentage;
        }, 0);
    };

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        
        try {
            const newPercentage = parseInt(data.percentage);
            const currentTotal = calculateTotalPercentage(currentPrize);
            
            if (currentTotal + newPercentage > 100) {
                setErrMsg(`Le total des pourcentages ne peut pas dépasser 100%. Reste disponible: ${100 - currentTotal}%`);
                setIsSubmitting(false);
                return;
            }
            
            const newReward = {
                name: data.name,
                percentage: newPercentage,
            };
            
            setCurrentPrize([...currentPrize, newReward]);
            setprizeData([...prizeData, { option: newReward.name, optionSize: newReward.percentage }]);
            setShowPopup(false);
            setIsSubmitting(false);
            setErrMsg("");
            
            // Reset form
            setValue('name', '');
            setValue('percentage', '');
            
        } catch (error) {
            console.log(error);
            setErrMsg("Une erreur est survenue");
            setIsSubmitting(false);
        }
    };

    const handleSpinClick = () => {
        if (!mustSpin) {
            const newPrizeNumber = Math.floor(Math.random() * data.length);
            setPrizeNumber(newPrizeNumber);
            setMustSpin(true);
        }
    };

    const handleRemoveReward = (index) => {
        const updatedRewards = currentPrize.filter((_, i) => i !== index);
        setCurrentPrize(updatedRewards);
        setprizeData(updatedRewards.map(reward => ({ option: reward.name, optionSize: reward.percentage })));
    };

    const handleEditPercentage = (index) => {
        setEditingIndex(index);
        setEditingPercentage(currentPrize[index].percentage.toString());
    };

    const handleSavePercentage = (index) => {
        const updatedPercentage = parseInt(editingPercentage);
        if (!isNaN(updatedPercentage) && updatedPercentage >= 0 && updatedPercentage <= 100) {
            const totalWithoutCurrent = calculateTotalPercentage(currentPrize, index);
            
            if (totalWithoutCurrent + updatedPercentage > 100) {
                setErrMsg(`Le total des pourcentages ne peut pas dépasser 100%. Reste disponible: ${100 - totalWithoutCurrent}%`);
                return;
            }

            const updatedRewards = [...currentPrize];
            updatedRewards[index].percentage = updatedPercentage;
            setCurrentPrize(updatedRewards);
            setprizeData(updatedRewards.map(reward => ({ option: reward.name, optionSize: reward.percentage })));
            setErrMsg("");
        }
        setEditingIndex(null);
        setEditingPercentage('');
    };

    const remainingPercentage = 100 - calculateTotalPercentage(currentPrize);

    const canSubmitForm = () => {
        const totalPercentage = calculateTotalPercentage(currentPrize);
        return totalPercentage === 100 && googleMapsUrl.trim() !== '' && wheelName.trim() !== '';
    };

    const handleFormSubmit = async () => {
        try {
            const totalPercentage = calculateTotalPercentage(currentPrize);
            
            // Add validation before submission
            if (totalPercentage < 100) {
                setFormError(`Le total des pourcentages doit être égal à 100%. Actuellement: ${totalPercentage}%`);
                return;
            }
            
            if (totalPercentage > 100) {
                setFormError(`Le total des pourcentages ne peut pas dépasser 100%. Actuellement: ${totalPercentage}%`);
                return;
            }

            setIsSubmitting(true);
            setFormError('');

            // First fetch the placeId
            try {
                const placeResponse = await apiRequest({
                    url: "/places/get-place-id-test",
                    method: "POST",
                    data: { placeUrl: googleMapsUrl },
                    token: user?.token
                });

                if (!placeResponse?.placeId) {
                    setFormError("Impossible de récupérer l'ID du lieu. Vérifiez l'URL Google Maps.");
                    setIsSubmitting(false);
                    return;
                }

                const prizeData = {
                    name: wheelName,
                    googleMapsUrl,
                    status: wheelStatus,
                    placeId: placeResponse.placeId,
                    reward: currentPrize.map(reward => ({
                        name: reward.name,
                        percentage: reward.percentage
                    }))
                };

                if (id) {
                    prizeData._id = id;
                }

                const response = await apiRequest({
                    url: id ? "/prize/update-prize" : "/prize/create-prize",
                    method: "POST",
                    data: prizeData,
                    token: user?.token
                });

                if (!response?.success && !response?.success) {
                    setFormError(response?.message || "Une erreur est survenue");
                } else {
                    window.location.replace("/dashboard");
                }
            } catch (error) {
                console.error("Error fetching placeId:", error);
                setFormError("Erreur lors de la récupération de l'ID du lieu");
            }

        } catch (error) {
            console.error(error);
            setFormError("Une erreur est survenue lors de la création/modification de la roue");
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleGoogleMapsUrlChange = (e) => {
        console.log("URL changed:", e.target.value); // Debug log
        setGoogleMapsUrl(e.target.value);
    };

    var seo = {
        title: "Venco | Créer une roue",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className={`dashboard create-prize ${menuOppened ? "oppened" : "closed"}`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">
                <h2 className="content-title">{pathname.includes("creer") ? "Créer une nouvelle " : "Modifier la "}roue</h2>
                <p className="content-subtitle">Créez vos roues ici. Donnez un nom à votre récompense, un statut, une rareté et assignez votre récompense à un lieu (pensez à créer un lieu avant de créer vos récompenses). N'hésitez pas à consulter nos tutoriaux ou à contacter le support si vous rencontrez le moindre problème.</p>

                <div className="content-wheel">
                    <div className="content-wheel-rewards">
                        <div className="content-wheel-rewards-header">
                            <h4 className="content-wheel-rewards-title">Les récompenses :</h4>
                        </div>
                        {currentPrize.map((reward, index) => (
                            <div key={index} className="content-wheel-rewards-card">
                                <h6 className="content-wheel-rewards-card-title">{reward.name}</h6>
                                {editingIndex === index ? (
                                    <div className="content-wheel-rewards-card-edit">
                                        <input
                                            type="number"
                                            value={editingPercentage}
                                            onChange={(e) => setEditingPercentage(e.target.value)}
                                            min="0"
                                            max="100"
                                            className="content-wheel-rewards-card-edit-input"
                                        />
                                        <button 
                                            className="content-wheel-rewards-card-edit-save"
                                            onClick={() => handleSavePercentage(index)}
                                        >
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 13L9 17L19 7" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                ) : (
                                    <div 
                                        className="content-wheel-rewards-card-percentage"
                                        onClick={() => handleEditPercentage(index)}
                                    >
                                        {reward.percentage}%
                                    </div>
                                )}
                                <button className="content-wheel-rewards-card-remove" onClick={() => handleRemoveReward(index)}>
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 18L18 6M6 6L18 18" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        ))}

                        <div className="content-wheel-reward-bottom">
                            <div className="content-wheel-rewards-add">
                                <div onClick={() => setShowPopup(true)} className='content-wheel-rewards-add-cta'>
                                    <svg className='content-wheel-rewards-add-svg' width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 12H18M12 6V18" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <p className='content-wheel-rewards-add-name'>Ajouter une récompense</p>
                                </div>
                            </div>
                        </div>

                        <div className="content-form">
                            <div className="content-form-group">
                                <div className="text-input">
                                    <label>Nom de la roue</label>
                                    <input
                                        type="text"
                                        placeholder="Ex: Roue du café des sports"
                                        value={wheelName}
                                        onChange={(e) => setWheelName(e.target.value)}
                                        className="text-input-input personnal-input"
                                    />
                                </div>
                                <p className="content-form-help">Donnez un nom à votre roue pour la retrouver facilement</p>
                            </div>

                            <div className="content-form-group">
                                <div className="text-input">
                                    <label>URL Google Maps</label>
                                    <input
                                        type="url"
                                        placeholder="https://maps.google.com/..."
                                        value={googleMapsUrl}
                                        onChange={(e) => setGoogleMapsUrl(e.target.value)}
                                        className="text-input-input personnal-input"
                                    />
                                </div>
                                <p className="content-form-help">Collez ici l'URL de votre établissement sur Google Maps</p>
                            </div>

                            <div className="content-form-group">
                                <div className="text-input">
                                    <label>Statut</label>
                                    <select
                                        value={wheelStatus}
                                        onChange={(e) => setWheelStatus(e.target.value)}
                                        className="text-input-input personnal-input"
                                    >
                                        <option value="draft">Brouillon</option>
                                        <option value="live">En ligne</option>
                                        <option value="off">Désactivé</option>
                                    </select>
                                </div>
                                <p className="content-form-help">Choisissez le statut de votre roue</p>
                            </div>

                            <div className="content-form-submit">
                                <button 
                                    onClick={handleFormSubmit}
                                    isDisabled={!canSubmitForm() || isSubmitting}
                                >
                                    {isSubmitting ? "Création..." : "Créer la roue"}
                                </button>
                                {!canSubmitForm() && (
                                    <p className="content-form-error">
                                        {calculateTotalPercentage(currentPrize) !== 100 
                                            ? `Le total des pourcentages doit être égal à 100%. Actuellement: ${calculateTotalPercentage(currentPrize)}%`
                                            : !wheelName.trim()
                                                ? "Le nom de la roue est requis"
                                                : "L'URL Google Maps est requise"}
                                    </p>
                                )}
                                {formError && <p className="content-form-error">{formError}</p>}
                            </div>
                        </div>
                    </div>

                    <div className="content-wheel-preview">
                        {prizeData.length > 0 && (
                            <>
                                <Wheel
                                mustStartSpinning={mustSpin}
                                prizeNumber={prizeNumber}
                                data={prizeData}
                                fontSize={16}
                                textDistance={50}
                                backgroundColors={['#f57b41', '#6cb8da']}
                                textColors={['#ffffff']}
                                />
                                <span className="content-wheel-rewards-remaining">
                                        Reste: {remainingPercentage}%
                                </span>
                            </>
                        )}
                    </div>
                </div>

                {showPopup && (
                    <div className="reward-popup-overlay">
                        <div className="reward-popup">
                            <h3>Ajouter une récompense</h3>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <TextInput
                                    label="Nom de la récompense"
                                    name="name"
                                    placeholder="Ex: Café gratuit"
                                    type="text"
                                    register={register("name", {
                                        required: "Nom de récompense requis"
                                    })}
                                    style="personnal-input"
                                    error={errors.name ? errors.name.message : ''}
                                />
                                <TextInput
                                    label="Pourcentage (%)"
                                    name="percentage"
                                    placeholder="Ex: 20"
                                    type="number"
                                    register={register("percentage", {
                                        required: "Pourcentage requis",
                                        min: { value: 0, message: "Le pourcentage minimum est 0" },
                                        max: { value: 100, message: "Le pourcentage maximum est 100" }
                                    })}
                                    style="personnal-input"
                                    error={errors.percentage ? errors.percentage.message : ''}
                                />
                                <div className="reward-popup-buttons">
                                    <button onClick={() => setShowPopup(false)}>
                                        Annuler
                                    </button>
                                    <button type="submit" isDisabled={isSubmitting}>
                                        {isSubmitting ? "Ajout..." : "Ajouter"}
                                    </button>
                                </div>
                                {errMsg && <p className="error-message">{errMsg}</p>}
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PrizeForm;