import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Navigate, useParams } from "react-router-dom";
import { useTour } from '@reactour/tour';
import { Helmet } from 'react-helmet-async';

import moment from 'moment';

import { useForm } from "react-hook-form";
import TopBar from '../../components/TopBar/TopBar';
import { fetchRewards, getUserInfo } from '../../utils';
import { UserLogin } from '../../redux/userSlice'
import Loading from '../../components/Loading/Loading';
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import LeftNav from "../../components/LeftNav/LeftNav";
import TopData from "../../components/TopData/TopData";
import RewardsTable from "../../components/RewardsTable/RewardsTable";
import PlacesTable from "../../components/PlacesTable/PlacesTable";
import PrizesTable from "../../components/PrizesTable/PrizesTable";

import './style.scss';


const Dashboard = () => {
    const { menuOppened, editMenuOppened } = useSelector((state) => state.global);
    const { rewards } = useSelector((state) => state.reward);
    const {user} = useSelector(state => state.user);
    const { currentStep, setSteps, setCurrentStep, setIsOpen } = useTour()
    const dispatch = useDispatch();

    // if (!user?.stripeCustomerId && user?.stripePaymentStatus !== "paid") {
    //     return <Navigate to={'/dashboard/abonnement'} replace />
    // }
    
    useEffect(() => {
        // localStorage.setItem("tutoViewed", "false")
        if(localStorage.getItem("tutoViewed") == null || localStorage.getItem("tutoViewed") == "false") {
            setIsOpen(true)
        }
    })

    var seo = {
        title: "Venco | Dashboard",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className={`dashboard ${menuOppened ? "oppened" : "closed"}`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">

                <div className="content-container">

                    <TopData />
                    <PrizesTable />
                    {/* <RewardsTable />
                    <PlacesTable /> */}

                </div>

            </div>
        </div>
    )
}

export default Dashboard