import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch} from "react-redux";
import { Link, NavLink, useParams, useLocation, Navigate } from "react-router-dom";
import { getUserInfo, fetchPrizesReviews, fetchRewards } from '../../utils';
import { Helmet } from 'react-helmet-async';
import { UserLogin } from '../../redux/userSlice';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr';  // Import French locale
import { useDownloadExcel } from 'react-export-table-to-excel';

// Set moment to use French locale
moment.locale('fr');

import LeftNav from "../../components/LeftNav/LeftNav";


import './style.scss';


const ClientsData = () => {
    const { menuOppened } = useSelector((state) => state.global);
    const { places } = useSelector((state) => state.place);
    const { prizes } = useSelector((state) => state.prize);
    const { reviews } = useSelector((state) => state.review);
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const tableRef = useRef(null);
    
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'avis-clients',
        sheet: 'Avis'
    });

    const fetchAllReviews = async (test) => {
        await fetchPrizesReviews(user?.token, dispatch, "reviews/get-prize-reviews", {"prizeId": test});
    };
    useEffect(() => {
        if (typeof user?.token !== undefined && Object.keys(prizes).length !== 0 && Object.keys(reviews).length === 0) {
            const fetchAllPrizeReviews = async () => {
                await Promise.all(prizes.map(prize => fetchAllReviews(prize._id)));
            };
            fetchAllPrizeReviews();
        }
    }, [prizes]);
    
    if (user?.stripeProductId !== process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_ID_PROD && user?.role !== "admin") {
        return (<Navigate to={'/dashboard'} replace />);
    };

    var seo = {
        title: "Venco | Analyse des données client",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    const [isExporting, setIsExporting] = useState(false);

    const handleExport = async () => {
        setIsExporting(true);
        try {
            await onDownload();
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <div className={`dashboard ${menuOppened ? "oppened" : "closed"}`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">
                <div className="content-container data-analytics">
                    <div className="header-section">
                        <div className="title-section">
                            <h1 className='data-analytics-title'>Analyse des données client</h1>
                            <p className="data-analytics-subtitle">Retrouvez ici toutes les informations sur les clients ayant laissé un avis chez vous. Retrouvez le nom, l'email, la note et l'avis que vous ont laissé vos clients.</p>

                            <div className="export-buttons">
                                <button 
                                    onClick={handleExport} 
                                    className="export-button"
                                    disabled={isExporting}
                                >
                                    {isExporting ? (
                                        <>
                                            Export en cours...
                                        </>
                                    ) : (
                                        <>
                                            Exporter en Excel
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="tableContainer">
                        <table id="table-to-xls" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th scope="col">Lieu</th>
                                    <th scope="col">Nom du client</th>
                                    <th scope="col">Récompense</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Note</th>
                                    <th scope="col">Avis</th>
                                    <th scope="col">Date de l'avis</th>
                                    <th scope="col">Lien de l'avis</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reviews.map((review, i) => {
                                    if (!review?.clientInfos) {
                                        return;
                                    }
                                    var placeReviewed = prizes.find(prize => prize?._id.toString() == review?.placeReviewed.toString());
                                    
                                    return (
                                        <tr key={i}>
                                            <td>{placeReviewed?.name}</td>
                                            <td>{review?.reviewerName}</td>
                                            <td>{review?.rewardUsed}</td>
                                            <td>{review?.reviewerEmail}</td>
                                            <td>{review?.clientInfos?.reviewRating}/5</td>
                                            <td>{review?.clientInfos?.reviewText}</td>
                                            <td>{review?.clientInfos?.reviewDate ? moment(review.clientInfos.reviewDate).format('LL') : ''}</td>
                                            <td><a href={review?.clientInfos?.reviewLink} target="_blank" rel="noopener noreferrer">{review?.clientInfos?.reviewLink && "Voir l'avis"}</a></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientsData