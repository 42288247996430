import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Wheel } from 'react-custom-roulette';
import { apiRequest } from '../../utils';
import Confetti from 'react-confetti';
import { useSelector, useDispatch } from "react-redux";
import { ClientLogin } from "../../redux/clientSlice";
import { jwtDecode } from "jwt-decode";
import axios from 'axios';
import moment from 'moment';
import { Trademark } from '../../components';

import './style.scss';

const ClientRewardRevamp = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { client } = useSelector((state) => state.client);

    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [wheelData, setWheelData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPrize, setCurrentPrize] = useState(null);
    const [showConfetti, setShowConfetti] = useState(false);
    const [wonPrize, setWonPrize] = useState(null);
    const [userPlan, setUserPlan] = useState('advanced');
    const [hideLanding, setHideLanding] = useState(false);

    const handleSpinClick = () => {
        if (!mustSpin) {
            const newPrizeNumber = Math.floor(Math.random() * wheelData.length);
            setPrizeNumber(newPrizeNumber);
            setMustSpin(true);
        }
    };

    useEffect(() => {
        if (!mustSpin && wheelData.length > 0) {
            // Show celebration when wheel stops
            setShowConfetti(true);
            setWonPrize(wheelData[prizeNumber]);

            // Stop confetti after 5 seconds
            setTimeout(() => {
                setShowConfetti(false);
            }, 5000);
        }
    }, [mustSpin]);

    useEffect(() => {
        const fetchPrizeData = async () => {
            try {
                const response = await apiRequest({
                    url: `/prize/fetch-prize/${id}`,
                    method: "GET"
                });

                console.log(response);

                if (response?.success && response.prize) {
                    const userPlanResponse = await apiRequest({
                        url: `/users/get-user-plan/${response.prize.userId}`,
                        method: "GET"
                    });
                    if(userPlanResponse?.subscriptionStatus == "canceled" || userPlanResponse?.subscriptionStatus == "unpaid" && userPlanResponse?.role !== "admin") {
                        setHideLanding(true);
                    }
                    if (userPlanResponse?.userPlan !== process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_ID_PROD && userPlanResponse?.role !== "admin") {
                        setUserPlan('basic');
                    }
                    setCurrentPrize(response.prize);
                    
                    const wheelRewards = response.prize.reward.map(reward => ({
                        option: reward.name,
                        optionSize: reward.percentage
                    }));

                    setWheelData(wheelRewards);
                } else {
                    console.error('Failed to fetch prize data:', response);
                }
            } catch (error) {
                console.error('Error fetching prize:', error);
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchPrizeData();
        }
    }, [id]);

    const handleCallbackResponse = (response) => {
        const userObject = jwtDecode(response.credential);
        dispatch(ClientLogin(userObject));
    };

    useEffect(() => {
        const signInDiv = document.getElementById("signInDiv");
        if (signInDiv) {
            /* global google */
            google.accounts.id.initialize({
                client_id: "657917244428-2bcjap48iujc3sn5ofnbgt56fvcaj92c.apps.googleusercontent.com",
                callback: handleCallbackResponse
            })

            google.accounts.id.renderButton(
                document.getElementById("signInDiv"),
                {
                    theme: "outline",
                    // size: "large",
                    text: "signin_with",
                    shape: "pill",
                    locale: "fr_FR",
                    width: `100%`
                }
            )

            google.accounts.id.prompt();
        }
    }, [currentPrize]);

    useEffect(() => {
        if (wonPrize && client) {
            // Send the won prize info to backend
            apiRequest({
                url: '/prize/verify-review',
                method: 'POST',
                data: {
                    prizeId: currentPrize._id,
                    clientEmail: client.email,
                    clientName: client.name,
                    wonPrize: wonPrize
                }
            });
        }
    }, [wonPrize]);

    if (loading) {
        return <div className="wheel-page loading">Chargement...</div>;
    }

    if (typeof currentPrize?.status !== "undefined" && currentPrize?.status !== "live" || hideLanding) {
        return (
            <div className="container-failed" style={{"background": "#f3f3f3", "width": "100svw", "height": "100svh", "display": "flex", "justify-content": "center", "align-items": "center"}}>
                <h1>Désolé cette page n'est pas disponible</h1>
            </div>
        );
    }

    return (
        <>
            <div className={`wheel-page ${userPlan === 'basic' ? 'trademark-visible' : ''}`}>
                {showConfetti && <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                    recycle={true}
                    numberOfPieces={200}
                />}

                <div className="wheel-container">
                    {wheelData.length > 0 ? (
                        <Wheel
                            mustStartSpinning={mustSpin}
                            prizeNumber={prizeNumber}
                            data={wheelData}
                            onStopSpinning={() => {
                                setMustSpin(false);
                            }}
                            fontSize={16}
                            textDistance={50}
                            backgroundColors={['#f57b41', '#6cb8da']}
                            textColors={['#ffffff']}
                            outerBorderColor="#282828"
                            outerBorderWidth={3}
                            innerRadius={0}
                            radiusLineColor="#282828"
                            radiusLineWidth={1}
                        />
                    ) : (
                        <div className="wheel-error">Impossible de charger la roue</div>
                    )}
                </div>

                <div className="wheel-controls">
                    {!wonPrize ? (
                        <>
                            <h1>Tournez la roue !</h1>
                            {Object.keys(client).length !== 0 ? <p>Découvrez votre récompense en faisant tourner la roue</p> : ''}
                            {Object.keys(client).length === 0 ? (
                                <div className="login-container">
                                    <p>Connectez-vous pour faire tourner la roue</p>
                                    <div
                                        id="signInDiv"
                                    >
                                    </div>
                                </div>
                            ) : (
                                <button
                                    className="spin-button"
                                    onClick={handleSpinClick}
                                    disabled={mustSpin || wheelData.length === 0}
                                >
                                    {mustSpin ? 'La roue tourne...' : 'Tourner la roue'}
                                </button>
                            )}
                        </>
                    ) : (
                        <div className="prize-won">
                            <h1>Félicitations ! 🎉</h1>
                            <p>Vous avez gagné :</p>
                            <div className="prize-name">{wonPrize.option}</div>
                            <p className="prize-instructions">
                                Vous recevrez votre récompense par email une fois que nous aurons vérifié votre avis !
                            </p>
                        </div>
                    )}
                </div>
            </div>
            {userPlan === 'basic' && <Trademark />}
        </>
    );
};

export default ClientRewardRevamp;