import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch} from "react-redux";
import { Link, NavLink, useParams, useLocation, Navigate } from "react-router-dom";
import CustomButton from '../../components/CustomButton/CustomButton';
import { apiRequest, fetchPlaces, fetchShippableProducts } from '../../utils';
import { SetShippableProductsWithPrice } from '../../redux/shopSlice';
import { Helmet } from 'react-helmet-async';



import LeftNav from "../../components/LeftNav/LeftNav";


import './style.scss';


const Shop = () => {
    const { menuOppened } = useSelector((state) => state.global);
    const { user } = useSelector((state) => state.user);
    const { shippableProduct, shippableProductWithPrice } = useSelector((state) => state.shop);
    const { prizes } = useSelector((state) => state.prize);
    const dispatch = useDispatch();

    const [firstProductCount, setfirstProductCount] = useState(1);
    const [productsCount, setproductsCount] = useState([1, 1]);
    const [productLocation, setproductLocation] = useState(["", ""]);

    // if (!user?.stripeCustomerId && user?.stripePaymentStatus !== "paid") {
    //     return <Navigate to={'/dashboard/abonnement'} replace />
    // }

    const fetchAllProducts = async () => {
        await fetchShippableProducts(user?.token, dispatch);
    };

    const fetchProductPrice = async (product) => {
        const productPrice = await apiRequest({
            url: "/stripe/retrieve-price",
            token: user?.token,
            data: { stripePriceId: product?.default_price },
            method: "POST",
        });
        const priceBody = await productPrice;
        dispatch(SetShippableProductsWithPrice({...product, priceBody}));
    }


    useEffect(() => {
        if (shippableProduct.length == 0) {
            fetchAllProducts();
        }
    }, [])
    useEffect(() => {
        if (shippableProduct.length !== 0 && shippableProductWithPrice.length !== shippableProduct.length) {
            shippableProduct.map((product) => {
                fetchProductPrice(product);
            })
        }
    }, [shippableProduct]);

    useEffect(() => {
        if (prizes.length > 0) {
            setproductLocation([
                prizes[0]._id
            ])
        }
    }, [prizes])


    const handleInputChange = (index, count) => {
        if (index == 0) {
            if (count == "plus") {
                setproductsCount([
                    productsCount[0] + 1,
                    productsCount[1]
                ])
            } else {
                setproductsCount([
                    productsCount[0] - 1,
                    productsCount[1]
                ])
            }
        } else {
            if (count == "plus") {
                setproductsCount([
                    productsCount[0],
                    productsCount[1] + 1
                ])
            } else {
                setproductsCount([
                    productsCount[0],
                    productsCount[1] - 1
                ])
            }
        }
    }
    const handleSelectChange = (event, index) => {
        if (index == 0) {
            setproductLocation([
                event.target.value,
                productLocation[1]
            ])
        } else {
            setproductLocation([
                productLocation[0],
                event.target.value
            ])
        }
    }

    const handlePayment = async (product, i) => {
        const res = await apiRequest({
            url: "/stripe/create-checkout-session-product",
            method: "POST",
            data: {
                priceId: product?.priceBody?.price?.id,
                quantity: productsCount[i],
                locationId: productLocation[i],
                customerEmail: user?.email ? user?.email : "" 
            },
            token: user?.token
        });

        const body = await res;
        window.location.href = body.url;
    }

    var seo = {
        title: "Venco | Boutique en ligne",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className={`dashboard ${menuOppened ? "oppened" : "closed"} shop`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">
                <h2 className="content-title">Boutique</h2>
                <p className="content-subtitle">Retrouvez tous les produits complémentaires à votre abonnement. Facilitez et améliorez l'expérience utilisateur.</p>
                {prizes?.length == 0 && <p className="content-subtitle">(Veuillez créer une roue avant d'acheter les produits complémentaires)</p>}

                <div className="content-products-container">
                    {shippableProductWithPrice.length !== 0 && shippableProductWithPrice?.map((product, i) => {                        
                        var priceInEur = (product?.priceBody?.price?.unit_amount / 100).toFixed(2).replace(".", ",");

                        return (
                            <div key={i} className="content-products-container-card">
                                <div className="content-products-container-card-top">
                                    <img src={product?.images[0]} alt="" />
                                </div>
                                <div className="content-products-container-card-bottom">
                                    <h4 className="content-products-container-card-bottom-title">{product?.name}</h4>
                                    <p className="content-products-container-card-bottom-price">{priceInEur}€</p>
                                    <div className="content-products-container-card-bottom-info-container">
                                        <select onChange={(e) => {handleSelectChange(e, i)}}>
                                            {prizes.length > 0 && prizes.map(prize => {
                                                return (<option value={`${prize?._id}`}>{prize?.name}</option>)
                                            })}
                                        </select>

                                        <div className="content-products-container-card-bottom-count">
                                            <span onClick={() => {handleInputChange(i, "moins")}}>-</span>
                                            <input type="number" readOnly min={"1"} max={"100"} value={productsCount[i]} defaultValue={productsCount[i]} name=""/>
                                            <span onClick={() => {handleInputChange(i, "plus")}}>+</span>
                                        </div>

                                    </div>
                                    {prizes.length > 0 && <button onClick={() => {handlePayment(product, i)}} className="content-products-container-card-bottom-cta">Acheter</button>}
                                    {prizes.length == 0 && <button disabled className="content-products-container-card-bottom-cta">Créez un lieu avant d'acheter</button>}
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}

export default Shop