import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet-async';
import Faq from 'react-faq-component';
import LandingNav from '../../components/LandingNav/LandingNav';
import HeroSection from '../../components/HeroSection/HeroSection';
import AnimationSection from '../../components/AnimationSection/AnimationSection';
import Footer from '../../components/Footer/Footer';
import PricingSection from '../../components/PricingSection/PricingSection';
import FaqSection from '../../components/FaqSection/FaqSection';
import TryCta from '../../components/TryCta/TryCta';

import video from "./video/tet.mp4"
import create_place from "./video/create_place.mp4"
import create_reward from "./video/create_reward.mp4";
import newaaaaa from "./video/new.mp4";
import newBanner from "./img/homeBennerNew.jpeg"

import './style.scss';

const LandingFonctionnalites = () => {
    const { user, editUser } = useSelector((state) => state.user);

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    var seo = {
        title: "Venco | La meilleure solution pour collecter davantage d'avis Google",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    var animationElements = [
        {
            video: create_place,
            title: "Créez vos lieux",
            desc: "Depuis votre dashboard créez vos lieux et adaptez leur interface utilisateur pour offrir à vos clients une expérience personnalisé."
        },
        {
            video: create_reward,
            title: "Créez vos récompenses",
            desc: "Depuis votre dashboard créez des récompenses et associez les à vos lieux. Utilisez les récompenses pré-conçu par l'équipe Venco ou créez des récompenses 100% personnalisé pour coller à votre image de marque."
        },
        {
            video: newaaaaa,
            title: "Laissez vos clients faire la suite",
            desc: "Une fois vos lieux et récompenses créé, utilisez les supports Venco disponible dans la boutique (chevaler NFC et stickers personnalisé) ou créez vos propre supports en utilisant le QRcode disponible dans votre dashboard pour permettre a vos clients de laisser un avis et reçevoir leur récompense."
        },
    ];

    return (
        <div className='landing_fonctionnalites dark'>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LandingNav />

            <div className="landing_fonctionnalites-container">

                <HeroSection img={newBanner} title="Un dashboard complet et fonctionnel" desc="Retrouvez vos lieux, vos récompenses, les données de vos clients, des statistiques sur vos avis et bien plus encore." />

                <AnimationSection elements={animationElements} />

                <PricingSection />

                <TryCta />

                <FaqSection />

                <Footer />

            </div>
        </div>
    )
}

export default LandingFonctionnalites;