import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const Verify = () => {
    const [verificationStatus, setVerificationStatus] = useState('Vérification en cours...');
    const { userId, token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/verify/${userId}/${token}`);
                console.log(response);
                
                if (response.statusText === "OK") {
                    setVerificationStatus('Email vérifié avec succès! Redirection...');
                    navigate('/login');
                }
            } catch (error) {
                setVerificationStatus('Échec de la vérification. Le lien est peut-être expiré.');
            }
        };

        verifyEmail();
    }, [userId, token, navigate]);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h2>{verificationStatus}</h2>
        </div>
    );
};

export default Verify; 