import { createSlice } from "@reduxjs/toolkit";
import {user} from "../assets/data"

const initialState = {
    prizes: {},
};

const prizeSlice = createSlice({
    name: "prizes",
    initialState,
    reducers: {
        getPrizes(state, action) {
            state.prizes = action.payload;
        },
    }
}) 

export default prizeSlice.reducer;

export function SetPrizes(prizes) {
    return (dispatch, getState) => {
        dispatch(prizeSlice.actions.getPrizes(prizes));
    }
}