import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Test, IsFetching, UpdateIsReviewValid, SetLoading } from '../../redux/clientSlice';
import { Outlet, Navigate, Route, Routes, useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import Lottie from 'react-lottie';
import animationData from './lottie/hand.json'
import ClientTopHeader from "../../components/ClientTopHeader/ClientTopHeader"
import { fetchReward } from '../../utils';
import 'moment/locale/fr';

import './style.scss';

moment.locale('fr');

const ClientReward = () => {
    const { client, isLoading } = useSelector((state) => state.client);
    const { currentPlace } = useSelector((state) => state.client);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const fetchReviewsNew = async () => {
        dispatch(IsFetching(true))

        await axios.get(`${process.env.REACT_APP_API_URL}/apify/fetch-last-ten-new/${currentPlace.placeId}`)
        .then(async res => {
            const names = res.data.reviews.reviews.map(item => item.user.name);
            dispatch(Test(names))

            console.log(res.data);

            var isCurrentReviewValid = false;
            var currentReviewIndex = 0;
            names.map((review, i) => {
                console.log(review.toLowerCase());
                console.log(client.name.toLowerCase());
                if (review.toLowerCase() == client.name.toLowerCase()) {
                    isCurrentReviewValid = true;
                    currentReviewIndex = i;
                }
            })
            dispatch(UpdateIsReviewValid(isCurrentReviewValid))

            var reviewLink = res.data.reviews.reviews[currentReviewIndex].link;
            var reviewRating = res.data.reviews.reviews[currentReviewIndex].rating;
            var reviewDate = res.data.reviews.reviews[currentReviewIndex].date;
            var reviewImages = res.data.reviews.reviews[currentReviewIndex].images;
            var reviewUser = res.data.reviews.reviews[currentReviewIndex].user;
            var reviewSnippet = res.data.reviews.reviews[currentReviewIndex].snippet;

            var clientInfos = {
                reviewLink,
                reviewRating,
                reviewDate,
                reviewImages,
                reviewUser,
                reviewSnippet
            }

            var placeId = currentPlace._id;
            var rewards = currentPlace.rewards;
            var fullRewards = [];
            var placeRewards = [];
            var placeCommonRewards = [];
            var placeRareRewards = [];
            var clientReward = {};
            var rareCount = 0;
            var commonCount = 0;

            var getFullRewards = async () => {
                for (const reward of rewards) {
                    var fullReward = await fetchReward(dispatch, `rewards/get-reward-basics/${reward}`);
                    fullRewards.push(fullReward);
                };
            };

            await getFullRewards();

            fullRewards.map((reward) => {
                if (reward.placeId == placeId && reward.status == "live") {
                    placeRewards.push({
                        reward: reward,
                        rarity: reward.rarity
                    });
                    if (reward.rarity == "rare") {
                        placeRareRewards.push(reward);
                    } else { 
                        placeCommonRewards.push(reward);
                    }
                    reward.rarity == "rare" ? rareCount++ : commonCount++;
                }
            })

            if(placeRewards.length == 1) {
                clientReward = placeRewards[0];
            } else {
                var randomNum = Math.random();
                if(randomNum < 0.80) {
                    // Chose a random common
                    if (commonCount == 1) {
                        clientReward = placeCommonRewards[0];
                    } else {
                        var randomNum = Math.random();
                        var result;
                        for (var i = 1; i <= commonCount; i++) {
                            if (randomNum < i / commonCount) {
                                result = i - 1;
                                break;
                            }
                        }
                        clientReward = placeCommonRewards[result]
                    }
                } else {
                    // Chose a random rare
                    if (commonCount == 1) {
                        clientReward = placeRareRewards[0];
                    } else {
                        var randomNum = Math.random();
                        var result;
                        for (var i = 1; i <= rareCount; i++) {
                            if (randomNum < i / rareCount) {
                                result = i - 1; 
                                break;
                            }   
                        }
                        clientReward = placeRareRewards[result];
                    }
                }
            }

            if (isCurrentReviewValid) {
                //TODO : Check si utilisateur a déjà déposé un avis

                axios.post(`${process.env.REACT_APP_API_URL}/reviews/check-user-has-already-claim-reward`, {
                    "clientEmail": client.email,
                    "placeReviewed": placeId
                })
                .then(res => {
                    if (res?.data?.reviewerAlreadyExist == false || res?.data?.data?.reviewerAlreadyExist == false) {

                        var now = moment();
                        var tomorrowSameTime = now.add(1, 'days');

                        axios.post(`${process.env.REACT_APP_API_URL}/reviews/create-review`, {
                            "placeReviewed": placeId,
                            "rewardUsed": clientReward?._id ? clientReward?._id : clientReward?.reward?._id,
                            "reviewerEmail": client.email,
                            "reviewerName": client.name,
                            "clientInfos": clientInfos,
                            "rewardIsValid": isCurrentReviewValid,
                            "availableFrom": tomorrowSameTime,
                            "availableFromDate": tomorrowSameTime.format('dddd D MMMM [à] HH[h]mm'),
                        })
                        .then (res => {
                            var QrRequest = `${process.env.REACT_APP_APP_URL}/verifier/${res.data.data._id}`;
                            var encodedQrRequest = encodeURIComponent(QrRequest);
                            axios.post(`https://api.qrserver.com/v1/create-qr-code/?data=${encodedQrRequest}&size=200x200&format=png`)
                                .then (res => {
                                    axios.post(`${process.env.REACT_APP_API_URL}/apify/send-email`, {
                                        name: client.name,
                                        email: client.email,
                                        reward: clientReward?._id ? clientReward : clientReward.reward, 
                                        qrCode: res,
                                        availableDate: tomorrowSameTime.format('dddd D MMMM [à] HH[h]mm'),
                                        isValid: isCurrentReviewValid
                                    })
                                    .then(res => { 
                                        console.log(res); 
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    })
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    } else {
                        axios.post(`${process.env.REACT_APP_API_URL}/apify/send-email`, {
                            name: client.name,
                            email: client.email,
                            reward: clientReward?._id ? clientReward : clientReward.reward, 
                            qrCode: res,
                            isValid: "alreadyUsed"
                        })
                        .then(res => { 
                            console.log(res); 
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            } else {
                axios.post(`${process.env.REACT_APP_API_URL}/apify/send-email`, {
                    name: client.name,
                    email: client.email,
                    placeId: currentPlace._id,
                    isValid: isCurrentReviewValid
                })
                .then(res => { 
                    console.log(res); 
                })
                .catch(err => {
                    console.log(err);
                })
            }
        }) 
        .catch(err => {
            console.log(err);
        })
    };

    useEffect(() => {
        console.log(Object.keys(client).length);
        if(Object.keys(client).length > 0) {
            setTimeout(() => {
                fetchReviewsNew()
                dispatch(SetLoading());
            }, 6000)
        } else {
            console.log(location.pathname.replace("reward", ""));
            navigate(location.pathname.replace("reward", ""))
        }
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className={`reward`}>
            <div className="reward-top" style={{backgroundImage: `url('${currentPlace?.image}'`}}>
                <ClientTopHeader />
            </div>
            <div className="reward-bottom">
                <div className="reward-bottom-content">
                    {!isLoading && (
                        <>
                            <h1>Merci !</h1>
                            <h4>Vous allez recevoir votre récompense par mail rapidement !</h4>
                        </>
                    )}
                    {isLoading && <Lottie 
                        options={defaultOptions}
                        height={"180px"}
                        width={"100%"}
                    />}
                </div>
            </div>
        </div>
    )
}

export default ClientReward;