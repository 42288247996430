import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch} from "react-redux";
import { Link, NavLink, useParams, useLocation, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import moment from 'moment';

import { useForm } from "react-hook-form";
import TopBar from '../../components/TopBar/TopBar';
import { fetchReward, fetchReview } from '../../utils';
import { UserLogin } from '../../redux/userSlice'
import Loading from '../../components/Loading/Loading';
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import LeftNav from "../../components/LeftNav/LeftNav";
import TopData from "../../components/TopData/TopData";
import RewardsTable from "../../components/RewardsTable/RewardsTable";
import PlacesTable from "../../components/PlacesTable/PlacesTable";

import {Html5Qrcode, Html5QrcodeScanner} from "html5-qrcode";


import './style.scss';


const Scan = () => {
    const { menuOppened } = useSelector((state) => state.global);
    const { reviewToVerify } = useSelector((state) => state.review);
    const { rewards } = useSelector((state) => state.reward);
    const { user } = useSelector(state => state.user);
    const [currentReview, setcurrentReview] = useState({});
    const [data, setData] = useState('No result');
    const [qrinit, setQrinit] = useState(false);
    const [htmlQr, setHtmlQr] = useState(null);
    const dispatch = useDispatch();

    // if (!user?.stripeCustomerId && user?.stripePaymentStatus !== "paid") {
    //     return <Navigate to={'/dashboard/abonnement'} replace />
    // }

    const handleQrReader = (html5QrCode) => {
        setQrinit(true);

        const fileinput = document.getElementById('qr-input-file');
        fileinput.addEventListener('change', e => {
            if (e.target.files.length == 0) {
                // No file selected, ignore 
                return;
            }

            const imageFile = e.target.files[0];
            // Scan QR Code
            html5QrCode.scanFile(imageFile, true)
            .then(decodedText => {
                // success, use decodedText
                window.location = decodedText;
            })
            .catch(err => {
                // failure, handle it.
                console.log(`Error scanning file. Reason: ${err}`)
            });
        });
    }

    var test = () => {
        const qrCodeSuccessCallback = (decodedText, decodedResult) => {
            window.location = decodedText;
        };
        const config = { fps: 10, qrbox: { width: 250, height: 250 } };
        htmlQr.start({ facingMode: "environment" }, config, qrCodeSuccessCallback);

        document.querySelector('.scan-cta').classList.remove("active")
        document.querySelector('#qr-input-file').classList.add("active")
        document.querySelector('.scan-stop').classList.add("active")
    }
    var test2 = () => {
        htmlQr.stop().then((ignore) => {
            document.querySelector('.scan-cta').classList.add("active")
            document.querySelector('#qr-input-file').classList.remove("active")
            document.querySelector('.scan-stop').classList.remove("active")
        }).catch((err) => {
        });
    }

    useEffect(() => {
        var html5QrCode = new Html5Qrcode("reader");
        if (!qrinit && window.innerWidth < 1024) {
            setHtmlQr(html5QrCode);
            handleQrReader(html5QrCode);
        }
    })

    var seo = {
        title: "Venco | Scanner la récompense client",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className={`dashboard ${menuOppened ? "oppened" : "closed"}`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">
                {/* <div className="content-header">
                    <div className="content-header-left">
                        <h1 className="content-header-left-title">Verifier</h1>
                    </div>
                    <div className="content-header-right">
                        <span className="search">Search</span>
                        <span className="ring">X</span>
                    </div>
                </div> */}

                <div className="content-container scan">
                    <h2 className='scan-title'>Scanner la récompense client</h2>
                    <p className="scan-subtitle">Scannez le QRcode de récompense de vos clients ici. Vous pouvez également utiliser l'application photo de votre téléphone pour faciliter cette tâche. N'hésitez pas à consulter nos tutoriaux ou à contacter le support si vous rencontrez le moindre problème.</p>

                    <button onClick={() => {test()}} className="scan-cta active">Commencer à scanner</button>

                    <div id="reader" width="600px"></div>
                    <input type="file" id="qr-input-file" accept="image/*"></input>

                    <p className='scan-stop' onClick={() => {test2()}}>arreter le scan</p>
                </div>

            </div>
        </div>
    )
}

export default Scan