import React, { useState, componentDidMount, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Navigate } from "react-router-dom";
import { set, useForm } from "react-hook-form";

import { getUserInfo, fetchPlaces, fetchRewards, handleFileUpload, apiRequest } from '../../utils';
import LeftNav from "../../components/LeftNav/LeftNav";
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import { Helmet } from 'react-helmet-async';

import './style.scss';


const Profile = () => {
    const { menuOppened, editMenuOppened } = useSelector((state) => state.global);
    const [istSubmitting, setistSubmitting] = useState(false);
    const {user} = useSelector(state => state.user);
    const [file, setFile] = useState(null);
    const [errMsg, setErrMsg] = useState("");
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" })
    const [userPlanId, setUserPlanId] = useState(null);

    // if (!user?.stripeCustomerId && user?.stripePaymentStatus !== "paid") {
    //     return <Navigate to={'/dashboard/abonnement'} replace />
    // }

    const afterFileUpload = async (e) => {
        const uriaa = e && (await handleFileUpload(e));
        setFile(uriaa); 
    };

    const onSubmit = async (data) => {
        setistSubmitting(true);
        try {
            var uri = file;

            if (!file) {
                uri = user?.profileUrl
            }

            var newData = uri ? { ...data, profileUrl: uri } : data;

            console.log(newData);

            const res = await apiRequest({
                url: "/users/update-user",
                method: "PUT",
                data: newData,
                token: user?.token
            });

            if(!res?.success) {
                console.log("Fail update !");
                setErrMsg(res?.message);
            } else {
                setErrMsg("");
                console.log("Success update !");
                console.log(res);
                window.location.reload()
            }
            setistSubmitting(false);
        } catch (error) {
            console.log(error);
            setistSubmitting(false);
        }
    }

    useEffect(() => {
        const fetchSubscriptionDetails = async () => {
            try {
                // Retrieve stripe subscription
                const res = await apiRequest({
                    url: "/stripe/retrieve-subscription",
                    method: "GET",
                    token: user?.token
                });
                const subscriptionData = await res;
                
                if (subscriptionData?.subscriptionDetail?.items?.data[0]?.price?.id) {
                    setUserPlanId(subscriptionData.subscriptionDetail.items.data[0].price.id);
                }
            } catch (error) {
                console.error("Error fetching subscription details:", error);
            }
        };

        if (user?.token) {
            fetchSubscriptionDetails();
        }
    }, [user?.token]);

    const handleCustomerPortal = async () => {
        try {
            const res = await apiRequest({
                url: "/stripe/create-portal-session",
                token: user?.token,
                method: "POST",
            });
            
            if (res?.url) {
                // Open in new tab instead of current window
                window.open(res.url, '_blank');
            } else {
                console.error("No portal URL received");
            }
        } catch (error) {
            console.error("Error redirecting to customer portal:", error);
        }
    };

    var seo = {
        title: "Venco | Mon compte",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className={`profile-page dashboard ${menuOppened ? "oppened" : "closed"}`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">
                <div className="content-container">
                    <div className="content-container-top">
                        <div className="content-container-top-left">
                            <img src={user?.profileUrl ? user?.profileUrl : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt="profil" className="content-container-top-left-img" />
                            <div className="content-container-top-left-content">
                                <h2 className="content-container-top-left-content-top">{user?.firstName + " " + user?.lastName}</h2>
                                <h4 className="content-container-top-left-content-bottom">Modifiez vos informations personnelles</h4>
                            </div>
                        </div>
                    </div>

                    <div className="content-container-bottom">
                        <h3 className="content-container-bottom-title">Modifier le profil</h3>
                        <span className="content-container-bottom-title-divider"></span>

                        <form className='content-container-bottom-form' onSubmit={handleSubmit(onSubmit)}>
                            <div className="content-container-bottom-form-content">
                                <TextInput
                                    label="Nom"
                                    name="lastName"
                                    placeholder="Votre nom"
                                    type="text"
                                    register={
                                    register("lastName", {
                                        required: "Votre nom est requis"
                                    })
                                    }
                                    style="personnal-input"
                                    error={errors.lastName ? errors.lastName.message : ''}
                                    value={user?.lastName ? user?.lastName : ""}
                                />
                                <TextInput
                                    label="Prénom"
                                    name="firstName"
                                    placeholder="Votre prénom"
                                    type="text"
                                    register={
                                    register("firstName", {
                                        required: "Votre prénom est requis"
                                    })
                                    }
                                    style="personnal-input"
                                    error={errors.firstName ? errors.firstName.message : ''}
                                    value={user?.firstName ? user?.firstName : ""}
                                />
                                <p className="text-input-label">Photo de profil</p>
                                <input type="file" accept='.jpg, .png, .jpeg' onChange={(e) => {afterFileUpload(e.target.files[0]); }} id="imgUpload" className="post-modal-form-image-uploader-input" />
                                <CustomButton isDisabled={istSubmitting ? true : false} type="submit" style="submit-cta" title={"Modifier mes informations"} />
                            </div>
                        </form>



                        <h3 className="content-container-bottom-title">Abonnement</h3>
                        <span className="content-container-bottom-title-divider"></span>

                        <div className="subscription-status-container">
                            <div className="subscription-info">
                                <h4>Status de l'abonnement: {user?.subscriptionStatus === 'active' ? 
                                    <span className="status-active">Actif</span> : 
                                    <span className="status-inactive">Inactif</span>
                                }</h4>
                                {user?.stripeProductName && (
                                    <p>Plan actuel: {user.stripeProductName}</p>
                                )}
                            </div>
                            <button 
                                onClick={handleCustomerPortal}
                                className="subscription-container-portal-button"
                            >
                                Gérer mon abonnement
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile