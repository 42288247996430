import { createSlice } from "@reduxjs/toolkit";
import {user} from "../assets/data"
import moment from 'moment';

const initialState = {
    reviews: [],
    setReviewCount: 0,
    weeklyReviewsCount: 0,
    pastWeeklyReviewsCount: 0,
    weeklyGrowth: 0,
    weeklyReviews: [
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
    ],
    monthlyReviewsCount: 0,
    monthlyReviews: [
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
        {avis: 0,},
    ],
    pastMonthlyReviewsCount: 0,
    monthlyGrowth: 0,
    reviewToVerify: {}
};

const reviewSlice = createSlice({
    name: "place",
    initialState,
    reducers: {
        getReviews(state, action) {

            console.log(action.payload);
            if (action.payload.length > 0) {
                console.log(action.payload.length);
                action.payload.map((e, i) => {
                    state.reviews.push(e);
                    state.setReviewCount += 1;
                })
            }
        },
        setWeeklyReviews(state, action) {
            var indexToUpdate = action.payload.index > 0 ? action.payload.index - 1 : action.payload.index + 6;
            var index = state.weeklyReviews[indexToUpdate];
            index.avis += 1;
            state.weeklyReviewsCount += 1;
        },
        setWeeklyReviewsDays(state, action) {
            var weeklyDays = action.payload.reviews;
            weeklyDays.map((day, index) => {
                state.weeklyReviews[(weeklyDays.length - 1) - index].name = day;
            });
        },
        setMonthlyReviewsDays(state, action) {
            var monthlyDays = action.payload.reviews;
            monthlyDays.map((day, index) => {
                state.monthlyReviews[(monthlyDays.length - 1) - index].name = day;
            });
        },
        setPastWeeklyReviewsCount(state, action) {
            state.pastWeeklyReviewsCount += 1;
        },
        setWeeklyGrowth(state, action) {
            state.weeklyGrowth = action.payload;
        },
        SetMonthlyReviews(state, action) {
            var indexToUpdate = action.payload.index - 1;
            var index = state.monthlyReviews[indexToUpdate];
            index.avis += 1;
            state.monthlyReviewsCount += 1;
        },
        resetMonthlyReviews(state, action) {
            state.monthlyReviews = [
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
                {avis: 0,},
            ]
            state.monthlyReviewsCount = 0;
            state.monthlyGrowth = 0;
        },
        setPastMonthlyReviewsCount(state, action) {
            state.pastMonthlyReviewsCount += 1;
        },
        setMonthlyGrowth(state, action) {
            state.monthlyGrowth = action.payload;
        },
        setReviewToVerify(state, action) {
            state.reviewToVerify = action.payload;
        },
    }
}) 

export default reviewSlice.reducer;

export function SetReviews(reviews) {
    return (dispatch, getState) => {
        dispatch(reviewSlice.actions.getReviews(reviews));
    }
}
export function SetWeeklyReviews(reviews, index) {
    return (dispatch, getState) => {
        dispatch(reviewSlice.actions.setWeeklyReviews({reviews, index}));
    }
}
export function SetWeeklyReviewsDays(reviews) {
    return (dispatch, getState) => {
        dispatch(reviewSlice.actions.setWeeklyReviewsDays({reviews}));
    }
}
export function SetMonthlyReviewsDays(reviews) {
    return (dispatch, getState) => {
        dispatch(reviewSlice.actions.setMonthlyReviewsDays({reviews}));
    }
}
export function SetPastWeeklyReviewsCount() {
    return (dispatch, getState) => {
        dispatch(reviewSlice.actions.setPastWeeklyReviewsCount());
    }
}
export function SetWeeklyGrowth(growth) {
    return (dispatch, getState) => {
        dispatch(reviewSlice.actions.setWeeklyGrowth(growth));
    }
}
export function SetMonthlyReviews(reviews, index) {
    return (dispatch, getState) => {
        dispatch(reviewSlice.actions.SetMonthlyReviews({reviews, index}));
    }
}
export function SetPastMonthlyReviewsCount() {
    return (dispatch, getState) => {
        dispatch(reviewSlice.actions.setPastMonthlyReviewsCount());
    }
}
export function SetMonthlyGrowth(growth) {
    return (dispatch, getState) => {
        dispatch(reviewSlice.actions.setMonthlyGrowth(growth));
    }
}
export function SetReviewToVerify(growth) {
    return (dispatch, getState) => {
        dispatch(reviewSlice.actions.setReviewToVerify(growth));
    }
}