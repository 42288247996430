import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import './index.css';
import App from './App';
import axios from 'axios';
import { store } from "./redux/store";
import { TourProvider } from '@reactour/tour';


const steps = [
    {
        selector: '.content-container-cards',
        content: 'Ici vous verrez toutes les données sur les avis récoltés via Venco.',
    },
    {
        selector: '.content-container-section_reward',
        content: 'Retrouvez toutes vos récompenses, triés par status',
    },
    {
        selector: '.content-container-section_place',
        content: 'Retrouvez tous vos lieux, triés également par status',
    },
    {
        selector: '.content-container-section_place .content-container-section-table-bottom_draft',
        content: 'Pour commencer, veuillez créer un lieu',
    },
    {
        selector: '.content-container-section_reward .content-container-section-table-bottom_draft',
        content: 'Puis créez une récompenses.',
    }
]

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            {/* <TourProvider
                steps={steps}
                onClickClose={({ setIsOpen }) => {
                    setIsOpen(false)
                    localStorage.setItem("tutoViewed", true)
                }}
                disableInteraction
                onClickMask={({ setCurrentStep, currentStep, steps, setIsOpen }) => {
                    if (steps) {
                        if (currentStep === steps.length - 1) {
                            setIsOpen(false)
                            localStorage.setItem("tutoViewed", true)
                        }
                        setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1))
                    }
                }}
                badgeContent={({ totalSteps, currentStep }) => currentStep + 1 + "/" + totalSteps}
                scrollSmooth
            > */}
                <App />
            {/* </TourProvider> */}
        </BrowserRouter>
    </Provider>
    // </React.StrictMode>
);

