import React, {useState} from 'react';
import { Link } from "react-router-dom";
import {useForm} from 'react-hook-form';

import TextInput from '../../components/TextInput/TextInput';
import Loading from '../../components/Loading/Loading';
import CustomButton from '../../components/CustomButton/CustomButton';
import { apiRequest } from '../../utils';
import { Helmet } from 'react-helmet-async';

import './style.scss';

const ResetPassword = () => {
  const [errMsg, setErrMsg] = useState("");
  const [istSubmitting, setistSubmitting] = useState(false);
  
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });

  const onSubmit = async (data) => {
    setistSubmitting(true);

    try {
      const res = await apiRequest({
        url: "/users/request-passwordreset",
        method: "POST",
        data,
      })

      if (res?.status === "failed") {
        setErrMsg(res);
      } else {
        setErrMsg(res);
      }
      setistSubmitting(false);
    } catch (error) {
      console.log(error);
      setistSubmitting(false);
    }
  }

  var seo = {
    title: "Venco | Mot de passe oublié",
    description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
    image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
    creator: "@briock_",
    url: "http://www.venco.fr/",
    site_name: "Venco"
}

  return (
    <div className='forgot-password'>

      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />

        {/* Twitter meta */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={seo.creator} />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />

        {/* Facebook meta */}
        <meta property="og:title" content={seo.title}/>
        <meta property="og:url" content="http://www.venco.fr/"/>
        <meta property="og:image" content={seo.image}/>
        <meta property="og:site_name" content={seo.site_name}/>
        <meta property="og:description" content={seo.description}/>
    </Helmet>

      <div className="forgot-password-content">
        <div className="forgot-password-content-logo" onClick={() => {window.location.replace("/")}}>
          <span>Venco</span>
        </div>

        <h1 className="forgot-password-content-title">Mot de passe oublié ?</h1>
        <p className="forgot-password-content-text">Entrez votre adresse email pour réinitialisez votre mot de passe</p>

        <form className='forgot-password-content-form' onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            name="email"
            placeholder="email@example.com"
            type="email"
            register={
              register("email", {
                required: "Adresse mail requise"
              })
            }
            style="email-input"
            error={errors.email ? errors.email.message : ''}
          />


          {errMsg?.message && (
            <span className={`forgot-password-content-error ${errMsg?.status == "failed" ? "failed" : "success"}`}>{errMsg?.message}</span>
          )}

          <CustomButton isDisabled={istSubmitting ? true : false} type="submit" style="submit-cta" title="Réinitialiser mon mot de passe" />

        </form>

        <p className="forgot-password-content-text no-account">Vous vous souvenez de votre mot dde passe ? <Link to="/login" className='forgot-password-content-left-link'>Me connecter</Link></p>
      </div>
    </div>
  )
}

export default ResetPassword