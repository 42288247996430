import axios from 'axios';

import { SetPlaces } from '../redux/placeSlice';
import { SetShippableProducts } from '../redux/shopSlice';
import { SetReviews, SetReviewToVerify } from '../redux/reviewSlice';
import { SetRewards } from '../redux/rewardSlice';
import { SetPrizes } from '../redux/prizeSlice';
import { SetPlace, SetCurrentPlaceId } from '../redux/clientSlice';

const API_URL = process.env.REACT_APP_API_URL;

export const API = axios.create({
    baseURL: API_URL,
    responseType: 'json'
});

export const apiRequest = async({ url, token, data, method }) => {

    console.log(url, token, data, method);

    try {
        const result = await API(url, {
            method: method || 'GET',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token ? `Bearer ${token}` : ''
            }
        });

        return result.data;
    } catch (error) {
        console.log(error);
        const err = error?.response?.data;
        return { status: err.success, message: err.message }
    }

};

export const handleFileUpload = async (uploadFile) => {
    const formData = new FormData();
    formData.append('file', uploadFile);
    formData.append('upload_preset', 'SOCIAL_MEDIA');

    try {
        const response = await axios.post(
            `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_ID}/image/upload`,
            formData
        )
        return response.data.secure_url;
    } catch (error) {
        console.log(error);
    }
};

export const likePost = async ({uri, token}) => {

    try {

        const res = await apiRequest({
            url: uri,
            token: token,
            method: 'POST'
        });

        return res;

    } catch (error) {
        console.log(error);
    }

};

export const deletePost = async (id, token) => {
    try {
        const res = await apiRequest({
            url: `/posts/${id}`,
            token: token,
            method: 'DELETE'
        });
        return;
    } catch (error) {
        console.log(error);
    }
};

export const getUserInfo = async (token, id) => {

    try {
        const uri = id === undefined ? "/users/get-user" : "users/get-user/" + id;

        const res = await apiRequest({
            url: uri,
            token: token,
            method: 'POST'
        });

        if(res?.message === "Authentication failed") {
            localStorage.removeItem('user');
            window.location.replace("/login");
        }

        return res?.user;

    } catch(error) {
        console.log(error);
    }

};

export const sendFriendRequest = async (id, token) => {
    try {
        const res = await apiRequest({
            url: `/users/friend-request`,
            token: token,
            method: 'POST',
            data: { 
                requestTo: id 
            }
        });
        return;
    } catch(error) {
        console.log(error);
    }

};

export const viewUserProfile = async ({id, token}) => {
    try {
        const res = await apiRequest({
            url: `/users/profile-view`,
            token: token,
            method: 'POST',
            data: {
                id: id
            }
        });
        return;
    } catch(error) {
        console.log('adazdaz' + error);
    }

};





//! NEW 
export const fetchPlaces = async (token, dispatch, uri, data) => {
    try {
        const res = await apiRequest({
            url: uri || '/places',
            token: token,
            method: 'POST',
            data: data || {}
        })

        dispatch(SetPlaces(res?.places))
        return;

    } catch(error) {
        console.log(error);
    }
};
export const fetchPlace = async (token, dispatch, uri) => {
    try {
        const res = await apiRequest({
            url: uri || '/places',
            token: token,
            method: 'POST'
        })

        dispatch(SetPlaces(res?.places))
        return res?.place;

    } catch(error) {
        console.log(error);
    }
};
export const fetchShippableProducts = async (token, dispatch, uri) => {
    try {
        const res = await apiRequest({
            url: "/stripe/retrieve-all-products",
            method: "POST"
        });
        const body = await res;

        dispatch(SetShippableProducts(body?.products?.data))
        return res?.place;

    } catch(error) {
        console.log(error);
    }
};
export const fetchRewards = async (token, dispatch, uri, data) => {
    try {
        const res = await apiRequest({
            url: uri || '/places',
            token: token,
            method: 'POST',
            data: data || {}
        })

        console.log(res);

        dispatch(SetRewards(res?.rewards))
        return;

    } catch(error) {
        console.log(error);
    }
};
export const fetchPrizes = async (token, dispatch, uri, data) => {
    try {
        const res = await apiRequest({
            url: uri || '/prizes',
            token: token,
            method: 'GET',
            data: data || {}
        })

        console.log(res);

        dispatch(SetPrizes(res?.prizes))
        return;

    } catch(error) {
        console.log(error);
    }
};
export const fetchReward = async (dispatch, uri) => {
    try {
        const res = await apiRequest({
            url: uri || '/places',
            method: 'POST',
        })
        return res.reward;

    } catch(error) {
        console.log(error);
    }
};
export const fetchPlacesReviews = async (token, dispatch, uri, data) => {
    try {
        const res = await apiRequest({
            url: uri || '/reviews/get-place-reviews',
            token: token,
            method: 'POST',
            data: data || {}
        })

        console.log(res);

        dispatch(SetReviews(res?.reviews))
        return;

    } catch(error) {
        console.log(error);
    }
};
export const fetchPrizesReviews = async (token, dispatch, uri, data) => {
    try {
        const res = await apiRequest({
            url: uri || '/reviews/get-prize-reviews',
            token: token,
            method: 'POST',
            data: data || {}
        })

        console.log(res?.reviews);

        dispatch(SetReviews(res?.reviews))
        return;

    } catch(error) {
        console.log(error);
    }
};
export const fetchPlaceBasics = async (dispatch, uri, data) => {
    try {
        const res = await apiRequest({
            url: uri || '/places/get-place-basics/',
            method: 'POST'
        })

        dispatch(SetPlace(res?.place))
        return;

    } catch(error) {
        console.log(error);
    }
};
export const fetchCurrentPlaceId = async (dispatch, data) => {
    try {
        const res = await apiRequest({
            url: '/places/get-place-id/',
            method: 'POST',
            data: {
                "placeData": data
            }
        })

        console.log(res);

        dispatch(SetCurrentPlaceId(res?.placeId))
        return;

    } catch(error) {
        console.log(error);
    }
};
export const fetchPlaceUi = async (dispatch, id) => {
    try {
        const res = await apiRequest({
            url: `/place-ui/get-place-ui/${id}`,
            method: 'POST'
        })

        console.log(res.placeUi);

        // dispatch(SetPlaceUi(res?.place))
        return res.placeUi;

    } catch(error) {
        console.log(error);
    }
};

export const fetchReview = async (token, dispatch, uri) => {
    try {
        const res = await apiRequest({
            url: uri || '',
            token: token,
            method: 'GET'
        })

        if (res.success == true) {
            dispatch(SetReviewToVerify(res?.review))
        }

        return res?.place;

    } catch(error) {
        console.log(error);
    }
};
export const updateReview = async (token, dispatch, uri, data) => {
    try {
        const res = await apiRequest({
            url: uri || '',
            token: token,
            method: 'POST',
            data
        })

        console.log(res);
        return res;

    } catch(error) {
        console.log(error);
    }
};
export const deleteReward = async (token, dispatch, uri, data) => {
    try {
        const res = await apiRequest({
            url: uri || '/rewards',
            token: token,
            method: 'DELETE',
            data
        })

        fetchRewards(token, dispatch, "rewards/get-rewards");
        return res?.status;
    } catch(error) {
        console.log(error);
    }
};
export const deletePlace = async (token, dispatch, uri) => {
    try {
        const res = await apiRequest({
            url: uri || '/rewards',
            token: token,
            method: 'DELETE'
        })

        fetchPlaces(token, dispatch, "places/get-places");
        return res?.status;
    } catch(error) {
        console.log(error);
    }
};