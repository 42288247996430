import { createSlice } from "@reduxjs/toolkit";
import {user} from "../assets/data"

const initialState = {
    cart: [],
    shippableProduct: [],
    shippableProductWithPrice: [],
};

const shopSlice = createSlice({
    name: "shop",
    initialState,
    reducers: {
        setShippableProducts(state, action) {
            state.shippableProduct = action.payload;
        },
        setShippableProductsWithPrice(state, action) {
            if (action.payload.active) {
                state.shippableProductWithPrice = [
                    ...state.shippableProductWithPrice,
                    action.payload
                ];
            }
        },
    }
}) 

export default shopSlice.reducer;

export function SetShippableProducts(shippableProduct) {
    return (dispatch, getState) => {
        dispatch(shopSlice.actions.setShippableProducts(shippableProduct));
    }
}
export function SetShippableProductsWithPrice(shippableProduct) {
    return (dispatch, getState) => {
        dispatch(shopSlice.actions.setShippableProductsWithPrice(shippableProduct));
    }
}