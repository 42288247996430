import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { AreaChart, Area, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getUserInfo, fetchPlacesReviews, fetchPrizesReviews, fetchRewards } from '../../utils';
import { SetMonthlyReviewsDays, SetWeeklyReviews, SetMonthlyReviews, SetWeeklyReviewsDays, SetPastWeeklyReviewsCount, SetWeeklyGrowth, SetPastMonthlyReviewsCount, SetMonthlyGrowth } from '../../redux/reviewSlice';
import moment from 'moment';

import './style.scss';

const TopData = () => {
    const { places, editRewards } = useSelector((state) => state.place);
    const { prizes } = useSelector((state) => state.prize);
    const { user, editUser } = useSelector((state) => state.user);
    const { reviews, setReviewCount } = useSelector((state) => state.review);
    const { weeklyReviews, editWeeklyReviews } = useSelector((state) => state.review);
    const { weeklyReviewsCount, editWeeklyReviewsCount } = useSelector((state) => state.review);
    const { pastWeeklyReviewsCount, editPastWeeklyReviewsCount } = useSelector((state) => state.review);
    const { weeklyGrowth, editWeeklyGrowth } = useSelector((state) => state.review);
    const { monthlyReviews, editMonthlyReviews } = useSelector((state) => state.review);
    const { monthlyReviewsCount, editMonthlyReviewsCount } = useSelector((state) => state.review);
    const { pastMonthlyReviewsCount, editPastMonthlyReviewsCount } = useSelector((state) => state.review);
    const { monthlyGrowth, editMonthlyGrowth } = useSelector((state) => state.review);
    const [allReviewsFetched, setAllReviewsFetched] = useState(false);
    const dispatch = useDispatch();
    
    const fetchAllReviews = async (test) => {
        await fetchPrizesReviews(user?.token, dispatch, "reviews/get-prize-reviews", {"prizeId": test});
    };

    useEffect(() => {
        if (typeof user?.token !== undefined && Object.keys(prizes).length !== 0 && Object.keys(reviews).length === 0) {
            const fetchAllPrizeReviews = async () => {
                await Promise.all(prizes.map(prize => fetchAllReviews(prize._id)));
                setAllReviewsFetched(true);
            };
            fetchAllPrizeReviews();
        } else if (typeof user?.token !== undefined && Object.keys(prizes).length > 0 && Object.keys(reviews).length > 0) {
            setAllReviewsFetched(true);
        }
    }, [prizes]);

    useEffect(() => {
        if (allReviewsFetched && setReviewCount === reviews?.length) {
            if (weeklyReviewsCount === 0) {
                reviews.map((review) => {
                    // If less than 1 week old : 

                    console.log(review);
                    

                    if ((moment(Date.now()).valueOf() - moment(review.createdAt).valueOf()) < 518400000) {
                        console.log("dddddddddd");
                        
                        var dayOfReview = moment(review.createdAt).isoWeekday();
                        var currentDay = moment(Date.now()).isoWeekday();
                        dayOfReview = dayOfReview == 7 ?  0 : dayOfReview;
    
                        var numberToAdd = 7 - currentDay;
                        var dayReviewAfter = dayOfReview + numberToAdd;
                        dayReviewAfter = dayReviewAfter > 7 ? dayReviewAfter - 7 : dayReviewAfter;
    
                        if (currentDay == dayOfReview) {
                            if (moment(Date.now()).valueOf() - moment(review.createdAt) > 86400000) {
                                dayReviewAfter= dayReviewAfter - 6;
                            }
                        }
                        console.log("dayReviewAfter");
                        console.log(dayReviewAfter);
                        
                        dispatch(SetWeeklyReviews(1, dayReviewAfter));
                    }
                    if ((moment(Date.now()).valueOf() - moment(review.createdAt).valueOf()) > 518400000 && (moment(Date.now()).valueOf() - moment(review.createdAt).valueOf()) < 1036800000) {
                        dispatch(SetPastWeeklyReviewsCount());
                    }
                })
            }
            if(monthlyReviewsCount === 0) {
                reviews.map((review) => {
                    // If less than 1 month old : 
                    if((moment(Date.now()).valueOf() - moment(review.createdAt).valueOf()) < 2592000000) {
                        var dayOfReview = moment(review.createdAt).date();
                        var currentDay = moment(Date.now()).date();
                        var numberToAdd = 30 - currentDay;
                        var dayReviewAfter = dayOfReview + numberToAdd;
                        dayReviewAfter = dayReviewAfter > 30 ? dayReviewAfter - 30 : dayReviewAfter;
                        dispatch(SetMonthlyReviews(1, dayReviewAfter));
                    }
                    if ((moment(Date.now()).valueOf() - moment(review.createdAt).valueOf()) > 2592000000 && (moment(Date.now()).valueOf() - moment(review.createdAt).valueOf()) < 5184000000) {
                        dispatch(SetPastMonthlyReviewsCount());
                    }
                })
            }
            var dayOfWeek = [];
            var dayOfMonth = [];
            var currentDayNumber = moment(Date.now()).isoWeekday();
            for (let index = 0; index < 7; index++) {
                dayOfWeek.push(moment.weekdays(currentDayNumber - index))
            }
            for (let index = 0; index < 30; index++) {
                dayOfMonth.push(moment(moment(Date.now()).valueOf() - (86400000 * index)).format("ll"))
            }
            dispatch(SetMonthlyReviewsDays(dayOfMonth));
            dispatch(SetWeeklyReviewsDays(dayOfWeek));
        }
    }, [reviews, setReviewCount, allReviewsFetched]);

    useEffect(() => {
        if (allReviewsFetched && setReviewCount === reviews?.length) {
            var isGraterThanLastWeek = weeklyReviewsCount > pastWeeklyReviewsCount;
            if(isGraterThanLastWeek) {
                var percentage = Math.round((100 * weeklyReviewsCount) / pastWeeklyReviewsCount);
            } else if (weeklyReviewsCount == pastWeeklyReviewsCount) {
                var percentage = 0;
            } else {
                var percentage = Math.round(((100 * weeklyReviewsCount) / pastWeeklyReviewsCount) - 100);
            }
            if (percentage == Infinity) {
                percentage = 0;
            }
            dispatch(SetWeeklyGrowth(percentage));
        }
    }, [pastWeeklyReviewsCount, setReviewCount, allReviewsFetched]);

    useEffect(() => {
        if (allReviewsFetched && setReviewCount === reviews?.length) {
            var isGraterThanLastMonth = monthlyReviewsCount > pastMonthlyReviewsCount;
            if(isGraterThanLastMonth) {
                var percentage = Math.round((100 * monthlyReviewsCount) / pastMonthlyReviewsCount);
            } else if (monthlyReviewsCount == pastMonthlyReviewsCount) {
                var percentage = 0;
            } else {
                var percentage = Math.round(((100 * monthlyReviewsCount) / pastMonthlyReviewsCount) - 100);
            }
            console.log(percentage);
            if (percentage == Infinity) {
                percentage = 0;
            }
            dispatch(SetMonthlyGrowth(percentage));
        }
    }, [pastMonthlyReviewsCount, setReviewCount, allReviewsFetched]);

    return (
        <div className="top-data">
            <div className="content-container-top">
                <h2 className="content-container-top-title">Nombre d'avis collectés</h2>
                <h4 className="content-container-top-sub_title">{reviews.length}</h4>
            </div>
            <div className="content-container-cards">
                <div className="content-container-cards-card">
                    <div className="left">
                        <p className="left-title">Nouveaux avis</p>
                        <div className="left-bottom">
                            <div className={`left-bottom-growth ${weeklyGrowth < 0 ? 'down' : ''}`}>
                                <span className="left-bottom-growth-count">{weeklyReviewsCount}</span>
                                <span className="left-bottom-growth-arrow">
                                    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 6V18M12 6L7 11M12 6L17 11" stroke="#007600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                                <div className="left-bottom-growth-percent">{weeklyGrowth}%</div>
                            </div>
                            <p className="left-bottom-text">Ces 7 derniers jours</p>
                        </div>
                    </div>
                    <div className="right">
                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                        width={100}
                        height={100}
                        data={weeklyReviews}
                        margin={{
                            top: 5,
                            right: 0,
                            left: 0,
                            bottom: 5
                        }}
                        >
                            <XAxis height={0} dataKey="name" />
                            <Tooltip />
                            <Area type="basis" dataKey="avis" stroke="#ffbc43" fill="#ffcd72" />
                        </AreaChart>
                    </ResponsiveContainer>
                    </div>
                </div>
                <div className="content-container-cards-card">
                    <div className="left">
                        <p className="left-title">Nouveaux avis</p>
                        <div className="left-bottom">
                            <div className={`left-bottom-growth ${monthlyGrowth < 0 ? 'down' : ''}`}>
                                <span className="left-bottom-growth-count">{monthlyReviewsCount}</span>
                                <span className="left-bottom-growth-arrow">
                                    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 6V18M12 6L7 11M12 6L17 11" stroke="#007600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                                <div className="left-bottom-growth-percent">{monthlyGrowth}%</div>
                            </div>
                            <p className="left-bottom-text">Ces 30 derniers jours</p>
                        </div>
                    </div>
                    <div className="right">
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                            width={100}
                            height={100}
                            data={monthlyReviews}
                            margin={{
                                top: 5,
                                right: 0,
                                left: 0,
                                bottom: 5
                            }}
                            >
                                <XAxis height={0} dataKey="name" />
                                <Tooltip />
                                <Area type="basis" dataKey="avis" stroke="#ffbc43" fill="#ffcd72" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopData