import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Navigate, useLocation } from "react-router-dom";
import './style.scss';

import logo from "./img/logo512.png"

const TryCta = () => {
    const [isIframeVisible, setIsIframeVisible] = useState(false);

    const toggleIframe = () => {
        setIsIframeVisible(!isIframeVisible);
    };

    return (
        <div className="iframe-toggler-container">
            <span className='iframe-toggler' onClick={toggleIframe}>
                <p className='iframe-toggler-text'>Essayer maintenant</p>
                <img src={logo} alt="logo venco" className='iframe-container-logo' />
                <svg xmlns="http://www.w3.org/2000/svg" width="49" height="53" viewBox="0 0 49 53" fill="none">
                    <path d="M14.3715 3.9501C10.4234 25.3003 23.6096 38.7766 40.1718 42.7446" stroke="#322F29" stroke-width="4" stroke-linecap="round"/>
                    <path d="M22.5156 42.4023C28.0733 45.5259 31.7818 47.5001 40.9409 43.1406" stroke="#322F29" stroke-width="4" stroke-linecap="round"/>
                    <path d="M32.7148 27.6699C33.4264 34.6728 35.9751 38.0975 41.0993 42.6493" stroke="#322F29" stroke-width="4" stroke-linecap="round"/>
                </svg>
            </span>
            <div className={`iframe-container ${isIframeVisible ? 'visible' : ''}`}>
                <div className='iframe-container-notch'></div>
                <div className='iframe-container-volume_top'></div>
                <div className='iframe-container-volume_bottom'></div>
                <div className='iframe-container-power'></div>
                <iframe 
                    src={`${process.env.REACT_APP_APP_URL}/client/6780eb411004e17cfc43ae47`}
                    frameborder="0"
                ></iframe>
            </div>
        </div>
    )
}

export default TryCta