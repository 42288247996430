import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import './style.scss';

import banner from "./img/banner_v2.png"

const VsSection = () => {
    return (
        <section id='landing-container-versus' className="landing-container-versus">
            <div className="landing-container-versus-content">

                <div className="landing-container-versus-content-left">
                    <h2 className="landing-container-versus-content-left-title">C'est le moment de prendre le dessus sur vos concurrents</h2>
                    <div className="landing-container-versus-content-left-grid">
                        <div className="landing-container-versus-content-left-grid-item">
                            <span className="landing-container-versus-content-left-grid-item-icon">
                            <svg width="800px" height="800px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M359.168 768H160a32 32 0 0 1-32-32V192H64a32 32 0 0 1 0-64h896a32 32 0 1 1 0 64h-64v544a32 32 0 0 1-32 32H665.216l110.848 192h-73.856L591.36 768H433.024L322.176 960H248.32l110.848-192zM832 192H192v512h640V192zM342.656 534.656a32 32 0 1 1-45.312-45.312L444.992 341.76l125.44 94.08L679.04 300.032a32 32 0 1 1 49.92 39.936L581.632 524.224 451.008 426.24 342.656 534.592z"/></svg>
                            </span>
                            <div className="landing-container-versus-content-left-grid-item-title-wrapper">
                                <p className='landing-container-versus-content-left-grid-item-title'>Dominez les résultats Google</p>
                                <p className='landing-container-versus-content-left-grid-item-desc'>Améliorez votre classement et devenez le numéro 1 dans les recherches locales.</p>
                            </div>
                        </div>
                        <div className="landing-container-versus-content-left-grid-item">
                            <span className="landing-container-versus-content-left-grid-item-icon">
                                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.13238 1C4.07859 1 3.10207 1.5529 2.5599 2.45651L0.615776 5.69672C0.17816 6.42608 0.0121122 7.42549 0.508798 8.32014C0.789678 8.82607 1.27459 9.55181 2 10.1205V20C2 21.6569 3.34315 23 5 23H8C9.10457 23 10 22.1046 10 21V15H14V21C14 22.1046 14.8954 23 16 23H19C20.6569 23 22 21.6569 22 20V10.1205C22.7254 9.55181 23.2103 8.82607 23.4912 8.32014C23.9879 7.42548 23.8218 6.42608 23.3842 5.69672L21.4401 2.45651C20.8979 1.5529 19.9214 1 18.8676 1H5.13238ZM20 10.9697C19.8391 10.9895 19.6725 11 19.5 11C18.1259 11 17.1126 10.3216 16.4364 9.60481C16.2632 9.4211 16.1082 9.23119 15.9705 9.04325C15.2167 9.98812 13.9542 11 12 11C10.0458 11 8.7833 9.98812 8.02952 9.04325C7.89183 9.23119 7.73684 9.4211 7.56355 9.60481C6.8874 10.3216 5.87405 11 4.5 11C4.32752 11 4.16089 10.9895 4 10.9697V20C4 20.5523 4.44772 21 5 21H8V15C8 13.8954 8.89543 13 10 13H14C15.1046 13 16 13.8954 16 15V21H19C19.5523 21 20 20.5523 20 20V10.9697ZM4.27489 3.4855C4.45561 3.1843 4.78112 3 5.13238 3H18.8676C19.2189 3 19.5444 3.1843 19.7251 3.4855L21.6692 6.72571C21.8324 6.99765 21.8127 7.2231 21.7426 7.34937C21.2851 8.17345 20.5493 9 19.5 9C18.8448 9 18.323 8.69006 17.8913 8.23245C17.4506 7.76524 17.1659 7.20393 17.0284 6.88399C16.8114 6.37951 16.3329 6.21388 16.0033 6.21248C15.674 6.21109 15.1982 6.37172 14.9752 6.8683C14.6702 7.54754 13.7982 9 12 9C10.2018 9 9.32978 7.54754 9.0248 6.8683C8.80182 6.37172 8.32598 6.21109 7.99667 6.21248C7.66706 6.21388 7.18855 6.37951 6.97164 6.88399C6.83407 7.20393 6.5494 7.76524 6.10869 8.23245C5.67703 8.69006 5.15524 9 4.5 9C3.45065 9 2.71491 8.17345 2.2574 7.34937C2.1873 7.2231 2.1676 6.99765 2.33076 6.72571L4.27489 3.4855Z" fill="#0F0F0F"/>
                                </svg>
                            </span>
                            <div className="landing-container-versus-content-left-grid-item-title-wrapper">
                                <p className='landing-container-versus-content-left-grid-item-title'>Surpassez la concurrence</p>
                                <p className='landing-container-versus-content-left-grid-item-desc'>prenez l’avantage sur vos concurrents en augmentant votre note moyenne et votre nombre d’avis.</p>
                            </div>
                        </div>
                        <div className="landing-container-versus-content-left-grid-item">
                            <span className="landing-container-versus-content-left-grid-item-icon">
                                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 14C21.2091 14 23 16 23 17.5C23 18.3284 22.3284 19 21.5 19H21M17 11C18.6569 11 20 9.65685 20 8C20 6.34315 18.6569 5 17 5M5 14C2.79086 14 1 16 1 17.5C1 18.3284 1.67157 19 2.5 19H3M7 11C5.34315 11 4 9.65685 4 8C4 6.34315 5.34315 5 7 5M16.5 19H7.5C6.67157 19 6 18.3284 6 17.5C6 15 9 14 12 14C15 14 18 15 18 17.5C18 18.3284 17.3284 19 16.5 19ZM15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <div className="landing-container-versus-content-left-grid-item-title-wrapper">
                                <p className='landing-container-versus-content-left-grid-item-title'>Attirez de nouveaux clients</p>
                                <p className='landing-container-versus-content-left-grid-item-desc'>Gagnez la confiance de prospects influencés par les avis positifs de vos clients.</p>
                            </div>
                        </div>
                        <div className="landing-container-versus-content-left-grid-item">
                            <span className="landing-container-versus-content-left-grid-item-icon">
                                <svg fill="#000000" width="800px" height="800px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31,7H1A1,1,0,0,0,0,8V24a1,1,0,0,0,1,1H31a1,1,0,0,0,1-1V8A1,1,0,0,0,31,7ZM25.09,23H6.91A6,6,0,0,0,2,18.09V13.91A6,6,0,0,0,6.91,9H25.09A6,6,0,0,0,30,13.91v4.18A6,6,0,0,0,25.09,23ZM30,11.86A4,4,0,0,1,27.14,9H30ZM4.86,9A4,4,0,0,1,2,11.86V9ZM2,20.14A4,4,0,0,1,4.86,23H2ZM27.14,23A4,4,0,0,1,30,20.14V23Z"/>
                                    <path d="M7.51.71a1,1,0,0,0-.76-.1,1,1,0,0,0-.61.46l-2,3.43a1,1,0,0,0,1.74,1L7.38,2.94l5.07,2.93a1,1,0,0,0,1-1.74Z"/>
                                    <path d="M24.49,31.29a1,1,0,0,0,.5.14.78.78,0,0,0,.26,0,1,1,0,0,0,.61-.46l2-3.43a1,1,0,1,0-1.74-1l-1.48,2.56-5.07-2.93a1,1,0,0,0-1,1.74Z"/>
                                    <path d="M16,10a6,6,0,1,0,6,6A6,6,0,0,0,16,10Zm0,10a4,4,0,1,1,4-4A4,4,0,0,1,16,20Z"/>
                                </svg>
                            </span>
                            <div className="landing-container-versus-content-left-grid-item-title-wrapper">
                                <p className='landing-container-versus-content-left-grid-item-title'>Générez plus de ventes</p>
                                <p className='landing-container-versus-content-left-grid-item-desc'>Les clients ayant laissé un avis reviendront chercher leur récompense et généreront davantage de ventes.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="landing-container-versus-content-right" style={{ backgroundImage: `url(${banner})` }}>
                </div>
            </div>
        </section>
    )
}

export default VsSection