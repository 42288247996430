import './style.scss';

import logo from "./svg/logo.png"

const Trademark = () => {
  return (
    <div className="trademark-container">
      <h6>Propuslé par <img src={logo} alt="" /></h6>
    </div>
  )
}

export default Trademark