import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const SubscriptionGuard = ({ children }) => {
    const { user } = useSelector(state => state.user);
    
    // Check if user has an active subscription
    const hasActiveSubscription = user?.subscriptionStatus === 'active';
    const isAdmin = user?.role === 'admin';

    if (!hasActiveSubscription && !isAdmin) {
        // Redirect to subscription page if no active subscription
        return <Navigate to="/dashboard/abonnement" replace />;
    }

    return children;
};

export default SubscriptionGuard; 