import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { TbSocial } from "react-icons/tb";
import TextInput from '../../components/TextInput/TextInput';
import Loading from '../../components/Loading/Loading';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useDispatch } from 'react-redux';
import { apiRequest } from '../../utils';
import { Helmet } from 'react-helmet-async';

import './style.scss';

const Register = () => {

  const { register, handleSubmit, getValues, formState: { errors } } = useForm({ mode: "onChange" })

  const [errMsg, setErrMsg] = useState("");
  const [istSubmitting, setistSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setistSubmitting(true);

    try {
      const res = await apiRequest({
        url: "/auth/register",
        method: "POST",
        data,
      })

      if (res?.status === "failed") {
        setErrMsg(res);
      } else {
        setErrMsg(res);
        console.log(res);
        console.log("success");

        //TODO : if success => redirect to stripe payment (then redirect to dahsbord and check if user if verified if not, popup to verify account)
        
      }
      setistSubmitting(false);
    } catch (error) {
      console.log(error);
      setistSubmitting(false);
    }
  }

  var seo = {
    title: "Venco | Création de compte",
    description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
    image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
    creator: "@briock_",
    url: "http://www.venco.fr/",
    site_name: "Venco"
}

  return (
    <div className='register'>

      <Helmet>
          <title>{seo.title}</title>
          <meta name="description" content={seo.description} />

          {/* Twitter meta */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={seo.creator} />
          <meta name="twitter:title" content={seo.title} />
          <meta name="twitter:description" content={seo.description} />
          <meta name="twitter:image" content={seo.image} />

          {/* Facebook meta */}
          <meta property="og:title" content={seo.title}/>
          <meta property="og:url" content="http://www.venco.fr/"/>
          <meta property="og:image" content={seo.image}/>
          <meta property="og:site_name" content={seo.site_name}/>
          <meta property="og:description" content={seo.description}/>
      </Helmet>

      <div className="register-content">
        <div className="register-content-logo" onClick={() => {window.location.replace("/")}}>
          <span>Venco</span>
        </div>

        <h1 className="register-content-title">Créer un compte</h1>
        <p className="register-content-text">Entrez vos informations</p>

        <form className='register-content-form' onSubmit={handleSubmit(onSubmit)}>
          <div className="register-content-form-personnal">
            <TextInput
              name="firstName"
              placeholder="Prénom"
              type="text"
              register={
                register("firstName", {
                  required: "Prénom requise"
                })
              }
              style="personnal-input"
              error={errors.firstName ? errors.firstName.message : ''}
            />
            <TextInput
              name="lastName"
              placeholder="Nom"
              type="text"
              register={
                register("lastName", {
                  required: "Nom requise"
                })
              }
              style="personnal-input"
              error={errors.lastName ? errors.lastName.message : ''}
            />
          </div>
          <TextInput
            name="email"
            placeholder="email@exemple.com"
            type="email"
            register={
              register("email", {
                required: "Adresse mail requise"
              })
            }
            style="email-input"
            error={errors.email ? errors.email.message : ''}
          />
          <TextInput
            name="password"
            placeholder="Mot de passe"
            type="password"
            register={
              register("password", {
                required: "Mot de passe requise"
              })
            }
            style="password-input"
            error={errors.password ? errors.password.message : ''}
          />
          <TextInput
            // name="password"
            placeholder="Confirmation du mot de passe"
            type="password"
            register={
              register("cPassword", {
                validate: (value) => {
                  const { password } = getValues();

                  if (password !== value) {
                    return "Mot de passe différents"
                  }
                },
              })
            }
            style="password-input"
            error={errors.cPassword && errors.cPassword.type === "validate" ? errors.cPassword?.message : ''}
          />

          {errMsg?.message && (
            <span className={`register-content-error ${errMsg?.status == "failed" ? "failed" : "success"}`}>{errMsg?.message}</span>
          )}

          <CustomButton isDisabled={istSubmitting ? true : false || errMsg?.message && (errMsg?.status == "failed" ? false : true)} type="submit" style="submit-cta" title="Créer mon compte" />

        </form>

        <p className="register-content-text no-account">Vous avez déjà un compte ? <Link to="/login" className='register-content-switch-link'>Me connecter</Link></p>

      </div>
      <div className="register-content-left">

      </div>
    </div>
  )
}

export default Register