import { combineReducers } from "@reduxjs/toolkit";

import userSlice from "./userSlice";
import themeSlice from "./theme";
import placeSlice from "./placeSlice";
import rewardSlice from "./rewardSlice";
import globalSlice from "./globalSlice";
import reviewSlice from "./reviewSlice";
import clientSlice from "./clientSlice";
import shopSlice from "./shopSlice";
import prizeSlice from "./prizeSlice";

const rootReducer = combineReducers({
    user: userSlice,
    theme: themeSlice,
    place: placeSlice,
    global: globalSlice,
    review: reviewSlice,
    shop: shopSlice,
    prize: prizeSlice,
    client: clientSlice,
    reward: rewardSlice
})

export {rootReducer};