import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { apiRequest } from '../../utils';
import './style.scss';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { Trademark } from '../../components';

const ClientLandingRevamp = () => {
    const { user } = useSelector((state) => state.user);
    let { id } = useParams()
    const [loading, setLoading] = useState(false);
    const [currentPrize, setCurrentPrize] = useState({});
    const [currentStep, setCurrentStep] = useState(0);
    const [swipeDirection, setSwipeDirection] = useState(null);
    const [reviewPosted, setReviewPosted] = useState(false);
    const [hideLanding, setHideLanding] = useState(false);
    const touchStart = useRef(null);
    const navigate = useNavigate();
    const [userPlan, setUserPlan] = useState('advanced');

    const steps = [
        {
            icon: (
                <DotLottieReact
                    src="https://lottie.host/3e98faab-e1fc-453f-920b-8a1ad192840a/6Yb6uHByx8.lottie"
                    loop
                    autoplay
                />
            ),
            title: "Partagez votre avis",
            description: "Laissez nous un avis sur google maps"
        },
        {
            icon: (
                <DotLottieReact
                    src="https://lottie.host/882ff7b0-ff07-43b4-90f3-f609d59a7ed0/7dBgfq2DTU.lottie"
                    loop
                    autoplay
                />
            ),
            title: "Revenez tourner la roue",
            description: "N'oubliez pas de revenir sur cet onglet pour tourner la roue"
        },
        {
            icon: (
                <DotLottieReact
                    src="https://lottie.host/ef49fd8c-41c2-4c80-8415-e42aacd02b82/NAWPghvB3E.lottie"
                    loop
                    autoplay
                />
            ),
            title: "Recevez votre récompense !",
            description: "Débloquez votre récompense par mail une fois votre avis vérifié"
        }
    ];

    const handleReviewClick = () => {
        setReviewPosted(true)
    };

    const fetchPrizeData = async () => {
        try {
            const response = await apiRequest({
                url: `/prize/fetch-prize/${id}`,
                method: "GET",
                token: user?.token
            });

            console.log(response);
            

            if (response?.success) {
                setCurrentPrize(response.prize);
                const userPlanResponse = await apiRequest({
                    url: `/users/get-user-plan/${response.prize.userId}`,
                    method: "GET"
                });

                if(userPlanResponse?.subscriptionStatus == "canceled" || userPlanResponse?.subscriptionStatus == "unpaid" && userPlanResponse?.role !== "admin") {
                    setHideLanding(true);
                }

                if (userPlanResponse?.userPlan !== process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_ID_PROD && userPlanResponse?.role !== "admin") {
                    setUserPlan('basic');
                }

                await apiRequest({
                    url: `/prize/increment-clicks/${id}`,
                    method: "PUT",
                    token: user?.token
                });
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Object.keys(currentPrize).length === 0) {
            setLoading(true);
            fetchPrizeData();
        }
    }, []);

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
            setSwipeDirection('left');
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
            setSwipeDirection('right');
        }
    };

    const handleTouchStart = (e) => {
        touchStart.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
        if (!touchStart.current) {
            return;
        }
        
        const touchEnd = e.changedTouches[0].clientX;
        const diff = touchStart.current - touchEnd;

        if (Math.abs(diff) < 50) {
            return; // Ignore small swipes
        }

        if (diff > 0) {
            // Swiped left
            handleNext();
        } else {
            // Swiped right
            handlePrevious();
        }

        touchStart.current = null;
    };

    if (typeof currentPrize?.status !== "undefined" && currentPrize?.status !== "live" || hideLanding) {
        return (
            <div className="container-failed" style={{"background": "#f3f3f3", "width": "100svw", "height": "100svh", "display": "flex", "justify-content": "center", "align-items": "center"}}>
                <h1>Désolé cette page n'est pas disponible</h1>
            </div>
        );
    }

    return (
        <>
            <div 
                className={`onboarding ${userPlan === 'basic' ? 'trademark-visible' : ''}`} 
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
            >
                <div className={`onboarding-content ${swipeDirection}`}>
                    <div className="icon-container">
                        {steps[currentStep].icon}
                    </div>
                    <h1>{steps[currentStep].title}</h1>
                    <p>{steps[currentStep].description}</p>
                </div>
                
                <div className="onboarding-footer">
                    <div className="progress-dots">
                        {steps.map((_, index) => (
                            <div 
                                key={index} 
                                className={`dot ${index === currentStep ? 'active' : ''}`}
                            />
                        ))}
                    </div>
                    <div className="button-group">
                        {currentStep > 0 && !reviewPosted && (
                            <button 
                                className="prev-button"
                                onClick={handlePrevious}
                            >
                                Précédent
                            </button>
                        )}
                        {!reviewPosted && currentStep !== steps.length - 1 && <button 
                            className="next-button"
                            onClick={handleNext}
                        >
                            {currentStep === steps.length - 1 ? 'Laisser un avis' : 'Suivant'}
                        </button>}
                        {!reviewPosted && currentStep === steps.length - 1 && (
                            <Link 
                                className='next-button post-a-review-cta' 
                                onClick={() => {
                                    if (currentStep === steps.length - 1) {
                                        handleReviewClick();
                                    }
                                }} 
                                target='_blank' 
                                to={`https://search.google.com/local/writereview?placeid=${currentPrize?.placeId}`}
                            >
                                {currentStep === steps.length - 1 ? 'Laisser un avis' : 'Suivant'}
                            </Link>
                        )}
                        {reviewPosted && (
                            <Link 
                                className='next-button post-a-review-cta' 
                                to={`/client/${id}/wheel`}
                            >
                                C'est fait !
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            {userPlan === 'basic' && <Trademark />}
        </>
    );
};

export default ClientLandingRevamp;