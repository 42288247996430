import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useParams, useLocation, Navigate } from "react-router-dom";
import { getUserInfo, fetchPlaces, fetchRewards, handleFileUpload, apiRequest } from '../../utils';
import LeftNav from "../../components/LeftNav/LeftNav";
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import { useTour } from '@reactour/tour';
import { Helmet } from 'react-helmet-async';


import { set, useForm } from "react-hook-form";

import './style.scss';


const LieuxForm = () => {
    const { menuOppened } = useSelector((state) => state.global);
    const location = useLocation();
    const [istSubmitting, setistSubmitting] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" })
    const [file, setFile] = useState(null);
    const {user} = useSelector(state => state.user);
    const [errMsg, setErrMsg] = useState("");
    const { places } = useSelector((state) => state.place);
    const [currentLocation, setcurrentLocation] = useState({});
    const [maxPlacesReached, setmaxPlacesReached] = useState(false);

    let { id } = useParams()
    let {pathname} = useLocation();

    // if (!user?.stripeCustomerId && user?.stripePaymentStatus !== "paid") {
    //     return <Navigate to={'/dashboard/abonnement'} replace />
    // }

    if (typeof id !== "undefined" && Object.keys(currentLocation).length == 0) {
        (Object.keys(places).length !== 0) && places.map((place, i) => {
            if (place._id == id) {
                setcurrentLocation(place)
            }
        })
    }

    const onSubmit = async (data) => {
        setistSubmitting(true);
    
        try {
            if (Object.keys(currentLocation).length == 0) {
                const uri = file && (await handleFileUpload(file));
                const newData = uri ? { ...data, image: uri } : data;
    
                const res = await apiRequest({
                    url: "/places/create-place",
                    method: "POST",
                    data: newData,
                    token: user?.token
                });
    
                if(!res?.success) {
                    console.log("Fail creation !");
                    setErrMsg(res?.message);
                } else {
                    setErrMsg("");
                    console.log("Success creation !");
                    window.location.replace("/dashboard");
                }
            } else {
                var uri = file;
                !file ? uri = currentLocation?.image : uri;

                var newData = uri ? { ...data, image: uri }: data;
                newData = { ...newData, _id: currentLocation._id }

                const res = await apiRequest({
                    url: "/places/update-place",
                    method: "POST",
                    data: newData,
                    token: user?.token
                });
    
                if(!res?.success) {
                    console.log("Fail update !");
                    setErrMsg(res?.message);
                } else {
                    setErrMsg("");
                    console.log("Success update !");
                    window.location.replace("/dashboard");
                }
            }
          setistSubmitting(false);
        } catch (error) {
          console.log(error);
          setistSubmitting(false);
        }
    
    }

    const afterFileUpload = async (e) => {
        const uriaa = e && (await handleFileUpload(e));
        setFile(uriaa); 
    };


    var maxPlaces = 1;
    if (user?.stripeProductId === "prod_PrwYviwlh2mGXf") {
        // Advanced plan
        maxPlaces = 3;
    } else if(user?.stripeProductId === process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT_ID_TEST) {
        // All in plan
        maxPlaces = 10;
    } else if (user?.stripeProductId === "prod_PrwSa5XbDQVAL5") {
        // Starter plan
        maxPlaces = 1;
    }
    if (maxPlaces == Object.keys(places).length && !maxPlacesReached) {
        setmaxPlacesReached(true)
    }

    var seo = {
        title: "Venco | Modification du lieu",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className={`dashboard create-place ${menuOppened ? "oppened" : "closed"}`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">
                <h2 className="content-title">{pathname.includes("creer") ? "Créer un nouveau " : "Modifier le "}lieu</h2>
                <p className="content-subtitle">Modifiez/créez vos lieux ici. Donnez un nom à votre lieu, un status, votre URL Google maps et ajoutez une image pour illustrer votre lieu dans le portail client (l'image pourra être modifiée/ajoutée plus tard). N'hésitez pas à consulter nos tutoriaux ou à contacter le support si vous rencontrez le moindre problème.</p>

                <form className='content-form-container' onSubmit={handleSubmit(onSubmit)}>
                    <div className="content-form-container-left">
                        <TextInput
                            label="Nom"
                            name="name"
                            placeholder="Le nom de votre lieu"
                            type="text"
                            register={
                            register("name", {
                                required: "Nom de lieu requis"
                            })
                            }
                            style="personnal-input"
                            error={errors.name ? errors.name.message : ''}
                            value={currentLocation?.name ? currentLocation?.name : ""}
                        />
                        <TextInput
                            label="URL de lieu"
                            name="placeUrl"
                            placeholder="https://www.google.fr/maps/place/Magasin+Boardriders+Quiksilver+Billabong+Roxy+Element+DC+Shoes+RVCA/@43.4100851,-1.6115773,16.14z/data=!4m6!3m5!1s0xd5113e84195ddcb:0x53a1468d429b290e!8m2!3d43.4119804!4d-1.6138073!16s%2Fg%2F1tfzythk?entry=ttu"
                            type="text"
                            help={{
                                imgUrl: "https://res.cloudinary.com/db9dszsos/image/upload/v1712065947/Capture_d_e%CC%81cran_2024-04-02_a%CC%80_15.42.32_tyiswo.png",
                                textContent: "Aller sur Google maps et copier l'url de votre lieu."
                            }}
                            register={
                            register("placeUrl", {
                                required: "URL de lieu requis"
                            })
                            }
                            style="personnal-input"
                            error={errors.placeUrl ? errors.placeUrl.message : ''}
                            value={currentLocation?.placeUrl ? currentLocation?.placeUrl : ""}
                        />
                        <CustomButton isDisabled={istSubmitting || (maxPlacesReached && pathname.includes("creer")) ? true : false} type="submit" style="submit-cta" title={pathname.includes("creer") ? "Créer un lieu" : "Modifier le lieu"} />
                        {(maxPlacesReached && pathname.includes("creer")) && <p className='content-form-container-left-erreur'>Vous avez atteint votre limite de lieux autorisé, vous pouvez <Link to={`/profile/${user?._id}`} className='content-form-container-left-erreur-link'>modifier votre abonnement</Link> pour débloquer plus de lieux.</p>}
                    </div>
                    <div className="content-form-container-right">
                        <SelectInput
                            label="Status"
                            name="status"
                            type="select"
                            register={
                                register("status", {
                                    required: "Status requis"
                                })
                            }
                            values={[
                                {
                                    "id": "draft",
                                    "name": "Brouillon"
                                },
                                {
                                    "id": "live",
                                    "name": "En ligne"
                                },
                                {
                                    "id": "off",
                                    "name": "Hors ligne"
                                }
                            ]}
                            style="personnal-input"
                            error={errors.status ? errors.status.message : ''}
                            value={currentLocation?.status ? currentLocation?.status : "live"}
                        />
                        <p className="text-input-label">Image du lieu</p>
                        <input type="file" accept='.jpg, .png, .jpeg' onChange={(e) => {afterFileUpload(e.target.files[0]); }} id="imgUpload" className="post-modal-form-image-uploader-input" />
                    </div>
                </form>

                {!pathname.includes("creer") && <div className="content-bottom-container">
                    <h2 className="content-title">Vous souhaitez personnaliser l'expérience client ?</h2>
                    <p className="content-subtitle">Personnalisez l'interface client ici. Modifiez l'image illustrant votre lieu, le texte et bien d'autre. N'hésitez pas à consulter nos tutoriaux ou à contacter le support si vous rencontrez le moindre problème.</p>
                    <div className="content-bottom-container-side">
                        <div className="content-bottom-container-side-left">
                            <p className="content-bottom-container-side-left-desc">Permettez à vos utilisateurs de profiter d'une expérience 100% personnalisée.</p>
                            <ul className="content-bottom-container-side-left-list">
                                <li className="content-bottom-container-side-left-list-items">Modifiez la description de votre lieu</li>
                                <li className="content-bottom-container-side-left-list-items">Modifiez l'apparence globale de toutes les pages utilisateurs</li>
                            </ul>
                            <Link className='navlink-cta' to={"personnaliser"}>Personnaliser l'interface client</Link>
                        </div>
                        <div className="content-bottom-container-side-right">
                            <iframe src={`${process.env.REACT_APP_APP_URL}/client/${currentLocation?._id}/`} frameborder="0"></iframe>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default LieuxForm