import React, { useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import './style.scss';
import { apiRequest } from '../../utils';

const ResetPasswordConfirm = () => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const { userId, token } = useParams();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setError('');
    setSuccess('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (formData.password !== formData.confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return;
    }

    try {
      const data = {
        userId: userId,
        token: token,
        password: formData.password
      };

      console.log('Sending data:', data);

      const response = await apiRequest({
        url: "/users/reset-password",
        method: "POST",
        data,
      });

      console.log(response);
      

      if (response.status === "SUCCESS") {
        setSuccess('Votre mot de passe a été réinitialisé avec succès');
      } else {
        setError(response.message || 'Une erreur est survenue');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('Une erreur est survenue lors de la connexion au serveur');
    }
  };

  return (
    <div className="reset-password-confirm">
      <div className="reset-password-confirm__container">
        <h1>Réinitialiser votre mot de passe</h1>
        {error && <div className="error-message">{error}</div>}
        {success && (
          <div className="success-message">
            {success}
            <Link to="/login" className="login-link">Se connecter</Link>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="password">Nouveau mot de passe</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              minLength="6"
              placeholder="Minimum 6 caractères"
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              minLength="6"
              placeholder="Confirmer le mot de passe"
            />
          </div>
          <button className="custom-button" type="submit">
            Réinitialiser le mot de passe
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordConfirm; 