import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useParams, useLocation, Navigate } from "react-router-dom";
import { fetchPlaceUi, apiRequest } from '../../utils';
import LeftNav from "../../components/LeftNav/LeftNav";
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import { Helmet } from 'react-helmet-async';


import { set, useForm } from "react-hook-form";

import './style.scss';


const ClientEditor = () => {
    const { menuOppened, editMenuOppened } = useSelector((state) => state.global);
    const location = useLocation();
    const [istSubmitting, setistSubmitting] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" })
    const [file, setFile] = useState(null);
    const {user} = useSelector(state => state.user);
    const [errMsg, setErrMsg] = useState("");
    const { places, editRewards } = useSelector((state) => state.place);
    const [currentLocation, setcurrentLocation] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [currentLocationUi, setcurrentLocationUi] = useState({});
    let { id } = useParams()
    let {pathname} = useLocation();

    // if (!user?.stripeCustomerId && user?.stripePaymentStatus !== "paid") {
    //     return <Navigate to={'/dashboard/abonnement'} replace />
    // }

    if (typeof id !== "undefined" && Object.keys(currentLocation).length == 0) {
        (Object.keys(places).length !== 0) && places.map((place, i) => {
            if (place._id == id) {
                setcurrentLocation(place)
            }
        })
    }

    const onSubmit = async (data) => {
        setistSubmitting(true);
    
        try {
            if (Object.keys(currentLocationUi).length == 0) {
                var newData = { ...data, placeId: currentLocation._id }

                const res = await apiRequest({
                    url: "/place-ui/create-place-ui",
                    method: "POST",
                    data: newData,
                    token: user?.token
                });
    
                if(!res?.success) {
                    console.log("Fail creation !");
                    setErrMsg(res?.message);
                } else {
                    setErrMsg("");
                    console.log("Success creation !");
                    window.location.reload();
                }
            } else {
                var newData = { ...data, _id: currentLocationUi._id }

                const res = await apiRequest({
                    url: "place-ui/update-place-ui",
                    method: "POST",
                    data: newData,
                    token: user?.token
                });
    
                if(!res?.success) {
                    console.log("Fail update !");
                    setErrMsg(res?.message);
                } else {
                    setErrMsg("");
                    console.log("Success update !");
                    window.location.reload();
                }
            }
          setistSubmitting(false);
        } catch (error) {
          console.log(error);
          setistSubmitting(false);
        }
    
    }

    const toggleFetchPlaceUi = async (id) => {
        var placeUi = await fetchPlaceUi(dispatch, id);
        setcurrentLocationUi(placeUi);
        setLoading(false)
    };
    useEffect(() => {
        if (Object.keys(currentLocation).length !== 0) {
            if(currentLocation.customUi.length !== 0) {
                setLoading(true)
                toggleFetchPlaceUi(currentLocation.customUi);
            }
        }

        document.querySelector('.text-input-label-help.place-name').addEventListener('click', () => {
            handlePlaceNameHelpClick()
        })
        document.querySelector('.text-input-label-help.place-desc').addEventListener('click', () => {
            handlePlaceDescHelpClick()
        })
        document.querySelector('.text-input-label-help.place-landing-title').addEventListener('click', () => {
            handlePlaceLandingTitleHelpClick()
        })
        document.querySelector('.text-input-label-help.place-landing-desc').addEventListener('click', () => {
            handlePlaceLandingDescHelpClick()
        })

    }, [currentLocation])


    const handlePlaceNameHelpClick = () => {
        document.querySelector("iframe").contentDocument.body.querySelector(".landing-top-title").style.cssText = `
            background: red;
        `;
        setTimeout(() => {            
            document.querySelector("iframe").contentDocument.body.querySelector(".landing-top-title").style.cssText = `
                background: unset;
            `;
        }, 1500)
    }
    const handlePlaceDescHelpClick = () => {
        document.querySelector("iframe").contentDocument.body.querySelector(".landing-top-desc").style.cssText = `
            background: red;
        `;
        setTimeout(() => {            
            document.querySelector("iframe").contentDocument.body.querySelector(".landing-top-desc").style.cssText = `
                background: unset;
            `;
        }, 1500)
    }
    const handlePlaceLandingTitleHelpClick = () => {
        document.querySelector("iframe").contentDocument.body.querySelector(".landing-bottom-content h1").style.cssText = `
            background: red;
        `;
        setTimeout(() => {            
            document.querySelector("iframe").contentDocument.body.querySelector(".landing-bottom-content h1").style.cssText = `
                background: unset;
            `;
        }, 1500)
    }
    const handlePlaceLandingDescHelpClick = () => {
        document.querySelector("iframe").contentDocument.body.querySelector(".landing-bottom-content h4").style.cssText = `
            background: red;
        `;
        setTimeout(() => {            
            document.querySelector("iframe").contentDocument.body.querySelector(".landing-bottom-content h4").style.cssText = `
                background: unset;
            `;
        }, 1500)
    }

    var seo = {
        title: "Venco | Personnalisation de l'interface client",
        description: "Améliorez votre e-réputation en offrant des récompenses personnalisés aux clients laissant un avis chez vous. Venco est l'outil indispensable pour fidéliser vos clients tout en recevant davantage d'avis Google. Tous les clients qui laisseront un avis Google en utilisant le service Venco pourront bénéficier d'une récompense et donc reviendront dans votre établissement.",
        image: "https://res.cloudinary.com/db9dszsos/image/upload/v1718784720/Capture_d_e%CC%81cran_2024-06-19_a%CC%80_10.11.38_zkzbto.png",
        creator: "@briock_",
        url: "http://www.venco.fr/",
        site_name: "Venco"
    }

    return (
        <div className={`dashboard create-place ${menuOppened ? "oppened" : "closed"}`}>

            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />

                {/* Twitter meta */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={seo.creator} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:description" content={seo.description} />
                <meta name="twitter:image" content={seo.image} />

                {/* Facebook meta */}
                <meta property="og:title" content={seo.title}/>
                <meta property="og:url" content="http://www.venco.fr/"/>
                <meta property="og:image" content={seo.image}/>
                <meta property="og:site_name" content={seo.site_name}/>
                <meta property="og:description" content={seo.description}/>
            </Helmet>

            <LeftNav />
            <div className="content">
                <h2 className="content-title">Personnaliser</h2>
                <p className="content-subtitle">Personnalisez l'interface client ici. Modifiez l'image illustrant votre lieu, le texte et bien d'autre. N'hésitez pas à consulter nos tutoriaux ou à contacter le support si vous rencontrez le moindre problème.</p>

                <div className="content-editor">
                    <div className="content-editor-left">
                    <form className='content-form-container ui' onSubmit={handleSubmit(onSubmit)}>
                        <div className="content-form-container-left">
                            <TextInput
                                label="Nom de l'établissement"
                                name="placeName"
                                placeholder="Le nom de l'établissement"
                                type="text"
                                register={
                                register("placeName", {
                                    required: "Nom d'établissement requis"
                                })
                                }
                                helpCta={{"className": "place-name"}}
                                style="personnal-input"
                                error={errors.placeName ? errors.placeName.message : ''}
                                value={currentLocationUi?.placeName ? currentLocationUi?.placeName : ""}
                            />
                            <TextInput
                                label="Description du lieu"
                                name="placeDescription"
                                placeholder="Un food truck d'exception"
                                type="text"
                                register={
                                register("placeDescription", {
                                    required: "Description de lieu requis"
                                })
                                }
                                helpCta={{"className": "place-desc"}}
                                style="personnal-input"
                                error={errors.placeDescription ? errors.placeDescription.message : ''}
                                value={currentLocationUi?.placeDescription ? currentLocationUi?.placeDescription : ""}
                            />
                            <CustomButton isDisabled={istSubmitting ? true : false} type="submit" style="submit-cta" title={"Modifier l'interface client"} />
                        </div>
                        <div className="content-form-container-right">
                            <TextInput
                                label="Titre de landing"
                                name="landingTitle"
                                placeholder="Profitez d'une récompense en échange d'un avis"
                                type="text"
                                register={
                                register("landingTitle", {
                                    required: "Titre de landing requis"
                                })
                                }
                                helpCta={{"className": "place-landing-title"}}
                                style="personnal-input"
                                error={errors.landingTitle ? errors.landingTitle.message : ''}
                                value={currentLocationUi?.landingTitle ? currentLocationUi?.landingTitle : ""}
                            />
                            <TextInput
                                label="Description de landing"
                                name="landingDescription"
                                placeholder="Moins de 2 minutes pour recevoir votre avis."
                                type="text"
                                register={
                                register("landingDescription", {
                                    required: "Titre de landing requis"
                                })
                                }
                                helpCta={{"className": "place-landing-desc"}}
                                style="personnal-input"
                                error={errors.landingDescription ? errors.landingDescription.message : ''}
                                value={currentLocationUi?.landingDescription ? currentLocationUi?.landingDescription : ""}
                            />
                        </div>
                    </form>
                    </div>
                    <div className="content-editor-right">
                        <iframe src={`${process.env.REACT_APP_APP_URL}/client/${currentLocation?._id}/`} frameborder="0"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientEditor