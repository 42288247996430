import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import moment from 'moment';
import { getUserInfo, fetchPlaces, fetchRewards, apiRequest } from '../../utils';

import './style.scss';

const PrizesTable = ({isCurrentLocation = false}) => {
    const { user } = useSelector((state) => state.user);
    const { places } = useSelector((state) => state.place);
    const { prizes } = useSelector((state) => state.prize);
    const [loading, setLoading] = useState(true);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [prizeToDelete, setPrizeToDelete] = useState(null);
    const [activeMenu, setActiveMenu] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.content-container-section-table-card-more')) {
                setActiveMenu(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // Count prizes by status
    const getPrizeCounts = () => {
        if (!prizes.length) return { draft: 0, live: 0, off: 0 };
        return prizes.reduce((acc, prize) => {
            acc[prize.status]++;
            return acc;
        }, { draft: 0, live: 0, off: 0 });
    };

    const { draft: draftPrizeCount, live: livePrizeCount, off: offPrizeCount } = getPrizeCounts();

    const handleDeleteClick = (prize) => {
        setPrizeToDelete(prize);
        setShowDeleteConfirm(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const response = await apiRequest({
                url: `/prize/delete-prize/${prizeToDelete._id}`,
                method: "DELETE",
                token: user?.token
            });

            if (response?.success) {
                // Refresh prizes list
                window.location.reload();
            }
        } catch (error) {
            console.error("Error deleting prize:", error);
        } finally {
            setShowDeleteConfirm(false);
            setPrizeToDelete(null);
        }
    };

    const handleEditPrize = (prize) => {
        navigate(`/roue/modifier/${prize._id}`, { 
            state: { 
                prizeData: prize 
            }
        });
    };

    const handlePreviewPrize = (prize) => {
        // Open preview in new tab
        window.open(`/client/${prize._id}`, '_blank');
    };

    const handleCopyLink = (prize) => {
        const prizeUrl = `${window.location.origin}/client/${prize._id}`;
        navigator.clipboard.writeText(prizeUrl)
            .then(() => {
                // You could add a toast notification here if you want
                console.log('Link copied to clipboard');
            })
            .catch((err) => {
                console.error('Failed to copy link:', err);
            });
    };

    const toggleMorePannel = (e, prizeId) => {
        e.stopPropagation();
        setActiveMenu(activeMenu === prizeId ? null : prizeId);
    };

    return (
        <div className="content-container-section content-container-section_reward">
            <div className="content-container-section-top">
                <h2 className="content-container-section-top-title">Vos roues</h2>
                {!isCurrentLocation && <Link className='content-container-section-top-cta' to={"/recompenses"}>Voir tout</Link>}
            </div>
            <div className="content-container-section-tables">
                <div className="content-container-section-table">
                    <div className="content-container-section-table-top">
                        <p className='content-container-section-table-top-name'>Brouillon</p>
                        <span className='content-container-section-table-top-count'>{draftPrizeCount ? draftPrizeCount : 0}</span>
                    </div>
                    <div className="content-container-section-table-cards">
                        {Object.keys(prizes).length !== 0 && prizes.map((prize, i) => {
                            if (prize.status !== "draft") {
                                return;
                            }

                            var updatedAt = new Date(prize.updatedAt);

                            return(
                                <div key={i} className="content-container-section-table-card">
                                    <h4 className="content-container-section-table-card-title">{prize.name}</h4>
                                    <p className="content-container-section-table-card-date"><span>Dernière modification : </span>{moment(updatedAt).fromNow()}</p>
                                    <span 
                                        onClick={(e) => toggleMorePannel(e, prize._id)} 
                                        className='content-container-section-table-card-more'
                                    >
                                        ...
                                    </span>

                                    <div className={`learnMore ${activeMenu === prize._id ? 'active' : ''}`}>
                                        <span onClick={() => handleEditPrize(prize)} className="learnMore-item">
                                            Modifier
                                        </span>
                                        <span onClick={() => handlePreviewPrize(prize)} className="learnMore-item">
                                            Prévisualiser
                                        </span>
                                        <span onClick={() => handleCopyLink(prize)} className="learnMore-item">
                                            Copier le lien
                                        </span>
                                        <span onClick={() => handleDeleteClick(prize)} className="learnMore-item learnMore-item--delete">
                                            Supprimer
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="content-container-section-table-bottom content-container-section-table-bottom_draft">
                        <Link to={"/roue/creer"} className='content-container-section-table-bottom-cta'>
                            <svg className='content-container-section-table-bottom-svg' width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12H18M12 6V18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <p className='content-container-section-table-bottom-name'>Ajouter une roue</p>
                        </Link>
                    </div>
                </div>
                <div className="content-container-section-table">
                    <div className="content-container-section-table-top">
                        <p className='content-container-section-table-top-name'>En ligne</p>
                        <span className='content-container-section-table-top-count'>{livePrizeCount ? livePrizeCount : 0}</span>
                    </div>
                    <div className="content-container-section-table-cards">
                        {Object.keys(prizes).length !== 0 && prizes.map((prize, i) => {
                            if (prize.status !== "live") {
                                return;
                            }

                            var updatedAt = new Date(prize.updatedAt);

                            return(
                                <div key={i} className="content-container-section-table-card">
                                    <h4 className="content-container-section-table-card-title">{prize.name}</h4>
                                    <p className="content-container-section-table-card-date"><span>Dernière modification : </span>{moment(updatedAt).fromNow()}</p>
                                    <span 
                                        onClick={(e) => toggleMorePannel(e, prize._id)} 
                                        className='content-container-section-table-card-more'
                                    >
                                        ...
                                    </span>

                                    <div className={`learnMore ${activeMenu === prize._id ? 'active' : ''}`}>
                                        <span onClick={() => handleEditPrize(prize)} className="learnMore-item">
                                            Modifier
                                        </span>
                                        <span onClick={() => handlePreviewPrize(prize)} className="learnMore-item">
                                            Prévisualiser
                                        </span>
                                        <span onClick={() => handleCopyLink(prize)} className="learnMore-item">
                                            Copier le lien
                                        </span>
                                        <span onClick={() => handleDeleteClick(prize)} className="learnMore-item learnMore-item--delete">
                                            Supprimer
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="content-container-section-table-bottom">
                        <Link to={"/roue/creer"} className='content-container-section-table-bottom-cta'>
                            <svg className='content-container-section-table-bottom-svg' width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12H18M12 6V18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <p className='content-container-section-table-bottom-name'>Ajouter une roue</p>
                        </Link>
                    </div>
                </div>
                <div className="content-container-section-table">
                    <div className="content-container-section-table-top">
                        <p className='content-container-section-table-top-name'>Désactivé</p>
                        <span className='content-container-section-table-top-count'>{offPrizeCount ? offPrizeCount : 0}</span>
                    </div>
                    <div className="content-container-section-table-cards">
                        {Object.keys(prizes).length !== 0 && prizes.map((prize, i) => {
                            if (prize.status !== "off") {
                                return;
                            }

                            var updatedAt = new Date(prize.updatedAt);

                            return(
                                <div key={i} className="content-container-section-table-card">
                                    <h4 className="content-container-section-table-card-title">{prize.name}</h4>
                                    <p className="content-container-section-table-card-date"><span>Dernière modification : </span>{moment(updatedAt).fromNow()}</p>
                                    <span 
                                        onClick={(e) => toggleMorePannel(e, prize._id)} 
                                        className='content-container-section-table-card-more'
                                    >
                                        ...
                                    </span>

                                    <div className={`learnMore ${activeMenu === prize._id ? 'active' : ''}`}>
                                        <span onClick={() => handleEditPrize(prize)} className="learnMore-item">
                                            Modifier
                                        </span>
                                        <span onClick={() => handlePreviewPrize(prize)} className="learnMore-item">
                                            Prévisualiser
                                        </span>
                                        <span onClick={() => handleCopyLink(prize)} className="learnMore-item">
                                            Copier le lien
                                        </span>
                                        <span onClick={() => handleDeleteClick(prize)} className="learnMore-item learnMore-item--delete">
                                            Supprimer
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="content-container-section-table-bottom">                        
                        <Link to={"/roue/creer"} className='content-container-section-table-bottom-cta'>
                            <svg className='content-container-section-table-bottom-svg' width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12H18M12 6V18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <p className='content-container-section-table-bottom-name'>Ajouter une roue</p>
                        </Link>
                    </div>
                </div>
            </div>

            {/* Add Delete Confirmation Popup */}
            {showDeleteConfirm && (
                <div className="delete-confirm-overlay">
                    <div className="delete-confirm-popup">
                        <h3>Confirmer la suppression</h3>
                        <p>Êtes-vous sûr de vouloir supprimer la roue "{prizeToDelete?.name}" ?</p>
                        <p className="delete-confirm-popup-warning">
                            Cette action est irréversible.
                        </p>
                        <div className="delete-confirm-popup-buttons">
                            <button 
                                className="delete-confirm-popup-button delete-confirm-popup-button--cancel"
                                onClick={() => setShowDeleteConfirm(false)}
                            >
                                Annuler
                            </button>
                            <button 
                                className="delete-confirm-popup-button delete-confirm-popup-button--confirm"
                                onClick={handleDeleteConfirm}
                            >
                                Supprimer
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PrizesTable