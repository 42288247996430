import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Navigate, useLocation } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Faq from 'react-faq-component';
import './style.scss';

const FaqSection = () => {
    const { user, editUser } = useSelector((state) => state.user);
    
    const faqData = {
        title: "Foire Aux Questions",
        rows: [
          {
            title: "Comment cela fonctionne ?",
            content: "Inscrivez-vous, créez une roue avec les récompenses que vous souhaitez offrir et c'est fait! Une fois votre roue créée, vous êtes libre de la promouvoir comme vous le souhaitez (par email, sur votre site, sur vos réseaux sociaux ou en utilisant les supports marketing Venco). De vôtre côté vous n'avez plus rien à faire, vos clients laisseront des avis Google et recevront automatiquement leur récompense par mail (si leur avis est valide)."
          },
          {
            title: "Comment savoir quand donner la récompense ?",
            content: "Une fois que l'avis de votre client est validé, nous envoyons automatiquement un mail avec un QRcode à votre client. Pour savoir si la récompense de votre client est valide et pour savoir quelle récompense il a reçu, il suffit de scanner le QRcode avec votre téléphone. C'est un peut flou ? N'hésitez pas à réserver une démo pour qu'on vous explique tout en détail !"
          },
          {
            title: "Pourquoi utiliser Venco ?",
            content: "Qui pourrait résister à un cadeau gratuit ? Cette phrase résume parfaitement l'intérêt de Venco. Le fait de récompenser un client en échange de son avis Google est une excellente façon de fidéliser vos clients tout en attirant une nouvelle clientèle grace à un meilleur référencement naturel sur Google."
          },
          {
            title: "Est-ce vraiment rentable si j'offre une récompense à chaque avis ?",
            content: "Chaque avis Google vous permet d'améliorer votre e-réputation tout en améliorant votre référencement, vous permettant d'être plus visible lors des recherches Google. De plus, c'est vous qui choisissez le type de récompense à offrir aux clients, ce qui vous permet de contrôler votre budget."
          },
          {
            title: "Est-ce long à mettre en place ?",
            content: "Vous avez 10 minutes devant vous ? Alors on est bon ! La seule tâche que vous ayez à faire est de créer votre roue et de la promouvoir en utilisant les supports marketing de vôtre choix (chevalet NFC, stickers QRcode, email, site web, réseaux sociaux, sac de commande...)."
          },
          {
            title: "Puis-je annuler à tout moment ?",
            content: "Evidemment !En souscrivant à Venco, vous ne vous engagez à rien ! Désabonnez-vous en moins de 2 clics quand vous le souhaitez depuis votre espace client Venco."
          }
        ]
    }

    return (
        <section id='landing-container-faq' className="landing-container-faq">
            <div className="landing-container-faq-content">

                <Faq data={faqData}/>

            </div>
        </section>
    )
}

export default FaqSection