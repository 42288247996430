import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Navigate, useLocation } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './style.scss';

const PricingSection = () => {
    const { user, editUser } = useSelector((state) => state.user);
    

    return (
        <section id='landing-container-pricing' className="landing-container-pricing">
            <div className="landing-container-pricing-content">
                <h4 className="landing-container-pricing-content-title">Un prix fixe et sans surprise</h4>

                {/* <p className="subscription-container-switch-text">
                    <span className='subscription-container-switch-text-span'>Facturé mensuellement</span>
                    <label class="switch">
                        <input type="checkbox" />
                        <span class="slider round"></span>
                    </label>
                    <span className='subscription-container-switch-text-span'>Facturé anuellement</span>
                </p> */}

                <div className="subscription-container-cards">
                    <div className={`subscription-container-cards-card`}>
                        <div className="subscription-container-cards-card-content">
                            <h4 className="subscription-container-cards-card-title">
                                <span>
                                <svg height="24px" width="24px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <g>
                                        <path class="st0" d="M491.225,154.831c-19.769-45.85-52.417-84.667-93.449-112.004C356.779,15.491,307.264-0.342,254.354,0.014
                                            c-35.262,0.221-68.962,7.602-99.522,20.77c-45.867,19.752-84.676,52.409-111.996,93.431
                                            c-27.337,40.998-43.169,90.513-42.83,143.439c0.238,35.253,7.62,68.954,20.787,99.524c19.735,45.833,52.401,84.667,93.431,111.995
                                            c40.998,27.337,90.513,43.17,143.422,42.812c35.262-0.22,68.962-7.61,99.524-20.761c45.867-19.76,84.675-52.426,111.995-93.44
                                            c27.337-40.996,43.186-90.521,42.83-143.422C511.757,219.085,504.376,185.383,491.225,154.831z M145.515,94.362
                                            c31.155-21.313,68.572-33.845,109.212-34.125c27.117-0.17,52.825,5.142,76.292,14.882c24.266,10.071,46.105,24.928,64.313,43.382
                                            l-280.396,1.807C124.219,110.653,134.468,101.947,145.515,94.362z M75.127,180.98c1.239-2.995,2.58-5.905,3.954-8.815
                                            l352.786-2.275c8.943,18.25,15.103,38.104,18.004,59.044l-388.098,2.503C63.979,213.764,68.527,196.855,75.127,180.98z
                                            M366.468,417.63c-31.138,21.322-68.555,33.844-109.212,34.133c-27.116,0.162-52.807-5.15-76.292-14.882
                                            c-24.249-10.079-46.088-24.927-64.296-43.39l280.379-1.799C387.782,401.34,377.533,410.053,366.468,417.63z M436.89,331.029
                                            c-1.239,2.978-2.596,5.905-3.97,8.807l-352.77,2.274c-8.976-18.259-15.136-38.096-18.021-59.052l388.115-2.494
                                            C448.021,298.245,443.474,315.146,436.89,331.029z"/>
                                    </g>
                                </svg>
                                </span>
                                Starter plan
                            </h4>
                            <p className="subscription-container-cards-card-subtitle">Idéal pour les indépendants gérant un seul lieu</p>

                            <p className="subscription-container-cards-card-price">49€<span className='subscription-container-cards-card-price-span'>/mois HT</span></p>

                            <ul className="subscription-container-cards-card-list">
                                <li className="subscription-container-cards-card-list-item">
                                    <span className="subscription-container-cards-card-list-item-span">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_429_11249)">
                                            <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_429_11249">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    1 roue de la fortune
                                </li>
                                <li className="subscription-container-cards-card-list-item">
                                    <span className="subscription-container-cards-card-list-item-span">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_429_11249)">
                                            <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_429_11249">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    Analyse de données basiques
                                </li>
                                <li className="subscription-container-cards-card-list-item">
                                    <span className="subscription-container-cards-card-list-item-span">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_429_11249)">
                                            <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_429_11249">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    Nombre d'avis illimité par mois
                                </li>
                                <li className="subscription-container-cards-card-list-item">
                                    <span className="subscription-container-cards-card-list-item-span">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_429_11249)">
                                            <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_429_11249">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    Support par email
                                </li>
                            </ul>
                        </div>

                        <Link to="/register" className={`subscription-container-cards-card-cta`}>S'abonner</Link>
                    </div>
                    <div className={`subscription-container-cards-card active`}>
                        <div className="subscription-container-cards-card-content">
                            <p className="subscription-container-cards-card-pill">Le plus populaire</p>
                            <h4 className="subscription-container-cards-card-title">
                                <span>
                                    <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <g>
                                            <rect y="239.908" class="st0" width="68.958" height="32.185"/>
                                            <rect x="443.059" y="239.908" class="st0" width="68.941" height="32.185"/>
                                            <polygon class="st0" points="26.248,370.057 42.341,397.943 102.053,363.464 85.961,335.586 	"/>
                                            <polygon class="st0" points="485.752,141.934 469.658,114.057 409.947,148.536 426.039,176.413 	"/>
                                            <polygon class="st0" points="114.065,469.658 141.943,485.752 176.422,426.039 148.544,409.947 	"/>
                                            <polygon class="st0" points="397.952,42.341 370.057,26.248 335.596,85.961 363.473,102.053 	"/>
                                            <rect x="239.916" y="443.051" class="st0" width="32.185" height="68.949"/>
                                            <rect x="239.916" class="st0" width="32.185" height="68.949"/>
                                            <polygon class="st0" points="335.596,426.039 370.057,485.752 397.952,469.658 363.473,409.947 	"/>
                                            <polygon class="st0" points="176.422,85.961 141.943,26.248 114.065,42.341 148.544,102.053 	"/>
                                            <polygon class="st0" points="409.947,363.464 469.658,397.943 485.752,370.057 426.039,335.586 	"/>
                                            <polygon class="st0" points="102.053,148.536 42.341,114.057 26.248,141.934 85.961,176.413 	"/>
                                            <path class="st0" d="M256.009,99.926c-86.207,0-156.074,69.877-156.074,156.074c0,86.197,69.868,156.074,156.074,156.074
                                                c86.188,0,156.074-69.878,156.074-156.074C412.083,169.802,342.197,99.926,256.009,99.926z"/>
                                        </g>
                                    </svg>
                                </span>
                                Advanced plan
                            </h4>
                            <p className="subscription-container-cards-card-subtitle">Idéal pour les entreprises souhaitant analyser leurs donnéeset récupérer les informations de leurs clients</p>

                            <p className="subscription-container-cards-card-price">149€<span className='subscription-container-cards-card-price-span'>/mois HT</span></p>

                            <ul className="subscription-container-cards-card-list">
                                <li className="subscription-container-cards-card-list-item">
                                    <span className="subscription-container-cards-card-list-item-span">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_429_11249)">
                                            <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_429_11249">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    Toutes les fonctionnalités du plan Starter
                                </li>
                                <li className="subscription-container-cards-card-list-item">
                                    <span className="subscription-container-cards-card-list-item-span">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_429_11249)">
                                            <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_429_11249">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    Nombre de roues de la fortune illimité
                                </li>
                                <li className="subscription-container-cards-card-list-item">
                                    <span className="subscription-container-cards-card-list-item-span">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_429_11249)">
                                            <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_429_11249">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    Pas de trademark (propulsé par Venco)
                                </li>
                                <li className="subscription-container-cards-card-list-item">
                                    <span className="subscription-container-cards-card-list-item-span">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_429_11249)">
                                            <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_429_11249">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    Analyse de données avancée
                                </li>
                                <li className="subscription-container-cards-card-list-item">
                                    <span className="subscription-container-cards-card-list-item-span">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_429_11249)">
                                            <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_429_11249">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    Accès aux données clients (email, note, avis..)
                                </li>
                                <li className="subscription-container-cards-card-list-item">
                                    <span className="subscription-container-cards-card-list-item-span">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_429_11249)">
                                            <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_429_11249">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    Support par email prioritaire
                                </li>
                                <li className="subscription-container-cards-card-list-item">
                                    <span className="subscription-container-cards-card-list-item-span">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_429_11249)">
                                            <path d="M20 7.00018L10 17.0002L5 12.0002" stroke="green" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_429_11249">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    Roue de la fortune personnalisable (a venir)
                                </li>
                            </ul>
                        </div>

                        <Link to="/register" className={`subscription-container-cards-card-cta`}>S'abonner</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PricingSection